import { Button, message, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Bar } from "react-chartjs-2";
import { useGetAttendanceQuery } from "../app/features/api/attendanceApiSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 1,
        callback: (yValue) => {
          return Math.floor(yValue); // format to your liking
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: true,
      text: "Employee attendance chart",
    },
  },
};

const AttendanceDetails = () => {
  const { attendanceId } = useParams();
  const { data, isLoading, isError } = useGetAttendanceQuery(attendanceId);
  const navigate = useNavigate();
  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  const WO = data.attendance.records.filter(
    (record) => record.attendance_status === "WO"
  ).length;

  const PL = data.attendance.records.filter(
    (record) => record.attendance_status === "PL"
  ).length;

  const UL = data.attendance.records.filter(
    (record) => record.attendance_status === "UL"
  ).length;
  const Present = data.attendance.records.filter(
    (record) => record.attendance_status === "Present"
  ).length;

  const chartData = {
    labels: [
      "Present",
      "Weekly Off (WO)",
      "Planned Leave (PL)",
      "Unplanned Leave (UL)",
    ],
    datasets: [
      {
        data: [Present, WO, PL, UL],
        backgroundColor: ["#4ade80", "#94a3b8", "#fdba74", "#fca5a5"],
      },
    ],
  };

  return (
    <main className="mt-14 p-2 md:p-4 max-w-[1700px] mx-auto">
      <Button
        className="flex items-center gap-x-1 ml-4 mt-4"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>
      <h2 className="text-left text-slate-700 my-6 ml-4">
        Name:{" "}
        <span className="text-primary">{data.attendance.employee_name}</span>
      </h2>
      <section className="w-[500px] lg:w-[900px] xl:w-[1100px] mr-auto">
        <Bar options={options} data={chartData} />
      </section>
    </main>
  );
};

export default AttendanceDetails;
