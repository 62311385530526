import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useEditEmployeeMutation,
  useGetAnEmployeeQuery,
} from "../app/features/api/employeesApiSlice";
import { useState } from "react";

const EditEmployee = () => {
  const { employeeId } = useParams();
  const { data, isLoading, isError } = useGetAnEmployeeQuery(employeeId);
  const [form] = Form.useForm();
  const [editEmployee, result] = useEditEmployeeMutation();
  const [isOverHead, setIsOverHead] = useState(false);

  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  // Possible values: "Driller", "Laborer", "Mini Operator", "Supervisor", "Cable Crew", "Locator"
  const positionList = [
    {
      value: "Driller",
      label: "Driller",
    },
    {
      value: "Laborer",
      label: "Laborer",
    },
    {
      value: "Mini Operator",
      label: "Mini Operator",
    },
    {
      value: "Supervisor",
      label: "Supervisor",
    },
    {
      value: "Cable Crew",
      label: "Cable Crew",
    },
    {
      value: "Locator",
      label: "Locator",
    },
  ];

  const onFinish = (values) => {
    let {
      date_of_birth,
      join_date,
      termination_date,
      street,
      state,
      city,
      zip,
      overhead,
      ...rest
    } = values;

    let employee = {};
    overhead = isOverHead;
    employee.overhead = overhead;

    if (date_of_birth) {
      employee.date_of_birth = date_of_birth.format("MM-DD-YYYY");
    }
    if (join_date) {
      employee.join_date = join_date.format("MM-DD-YYYY");
    }
    if (termination_date) {
      employee.termination_date = termination_date.format("MM-DD-YYYY");
    }

    let home_address = {};
    if (street) {
      home_address.street = street;
    }
    if (state) {
      home_address.state = state;
    }
    if (city) {
      home_address.city = city;
    }
    if (zip) {
      home_address.zip = zip;
    }

    if (Object.keys(home_address).length > 0) {
      employee.home_address = home_address;
    }

    employee = { ...employee, ...rest, id: employeeId };

    editEmployee(employee).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("Employee updated successfully!");
        navigate("/employees");
      }
    });
  };

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  return (
    <main className="mt-14 p-2 md:p-4 ">
      <Button
        className="flex items-center gap-x-1 ml-4"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>
      <div className="max-w-5xl mt-4 lg:mt-8">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.first_name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="middle_name"
            label="Middle Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
            initialValue={data?.employee?.middle_name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
            initialValue={data?.employee?.last_name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="date_of_birth"
            label="Date of Birth"
            rules={[{ required: false, message: "" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Date of birth (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            hasFeedback
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.phone}
          >
            <Input addonBefore="+1" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="street"
            label="Home Address"
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.home_address?.street}
          >
            <Input.TextArea />
          </Form.Item>
          <div className="flex justify-end">
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: false, message: "" }]}
              initialValue={data?.employee?.home_address?.city}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: false, message: "" }]}
              initialValue={data?.employee?.home_address?.state}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zip"
              label="Zip Code"
              rules={[{ required: false, message: "" }]}
              initialValue={data?.employee?.home_address?.zip}
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.position}
          >
            <Select
              options={positionList}
              placeholder="Select employee position"
            />
          </Form.Item>

          <Form.Item
            name="daily_wage"
            label="Daily Wage"
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.daily_wage}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="commission"
            label="Commission"
            rules={[{ required: false, message: "" }]}
            initialValue={data?.employee?.commission}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="join_date"
            label="Join Date"
            rules={[{ required: false, message: "" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Employee joining date (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="termination_date"
            label="Termination Date"
            rules={[{ required: false, message: "" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Employee termination date (MM-DD-YYYY)"
            />
          </Form.Item>
          <Form.Item
            name="overhead"
            label="Overhead Status"
            rules={[{ required: false, message: "" }]}
          >
            <Checkbox
              checked={isOverHead}
              onChange={(e) => setIsOverHead(e.target.checked)}
            >
              Overhead Employee
            </Checkbox>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-green-500"
              loading={result.isLoading}
            >
              Edit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default EditEmployee;
