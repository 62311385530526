import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";

export const projectsApiSlice = createApi({
  reducerPath: "projectsApiSlice",
  tagTypes: ["Projects", "Project", "Contractors"],
  // eslint-disable-next-line no-undef
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // fetch all projects
    getProjects: builder.query({
      query: () => "/api/projects",
      providesTags: ["Projects"],
    }),

    // fetch a single project
    getProject: builder.query({
      query: (projectId) => `/api/projects/project/${projectId}`,
      providesTags: ["Project"],
    }),

    // create project
    createProject: builder.mutation({
      query: (body) => ({
        url: "/api/projects/create-project",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),

    // edit project
    editProject: builder.mutation({
      query: (body) => ({
        url: "/api/projects/edit-project",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),

    // add task
    addTask: builder.mutation({
      query: (body) => ({
        url: "/api/projects/add-task",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),

    // edit task
    editTask: builder.mutation({
      query: (body) => ({
        url: "/api/projects/edit-task",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),

    // get contractors
    getContractors: builder.query({
      query: () => `/api/projects/contractors`,
      providesTags: ["Contractors"],
    }),

    // add contractor
    addContractor: builder.mutation({
      query: (body) => ({
        url: "/api/projects/contractors/add-contractor",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contractors"],
    }),

    // edit contractor
    editContractor: builder.mutation({
      query: (body) => ({
        url: "/api/projects/contractors/edit-contractor",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contractors"],
    }),

    // delete contractor
    deleteContractor: builder.mutation({
      query: (body) => ({
        url: "/api/projects/contractors/delete-contractor",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useEditProjectMutation,
  useAddTaskMutation,
  useEditTaskMutation,
  useGetProjectQuery,
  useGetContractorsQuery,
  useAddContractorMutation,
  useEditContractorMutation,
  useDeleteContractorMutation,
} = projectsApiSlice;
