import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button, Checkbox, Descriptions, message, Spin } from "antd";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAssetByIdQuery } from "../app/features/api/assetsApiSlice";

const AssetDetails = () => {
  const { assetId } = useParams();
  const { data, isLoading, isError } = useGetAssetByIdQuery(assetId);

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  console.log(data);

  return (
    <main className="mt-14 p-2 md:p-4">
      <Button
        className="flex items-center gap-x-1 my-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>

      <Descriptions title="Asset Info" bordered>
        <Descriptions.Item label="Tag">
          <span className="font-semibold">{data.asset.tag}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <span className="font-semibold">{data.asset.type}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Assigned To">
          <span className="font-semibold">{data.asset.assigned_to}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Tag Expiration">
          <span className="font-semibold">
            {data.asset.tag_expiration
              ? format(new Date(data.asset.tag_expiration), "MM-dd-yyyy")
              : "N/A"}
          </span>
        </Descriptions.Item>
        {data?.asset?.type?.includes("Vehicle") && (
          <>
            <Descriptions.Item label="Vehicle Name">
              <span className="font-semibold">
                {data.asset.vehicle_info.name}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle Year">
              <span className="font-semibold">
                {data.asset.vehicle_info.year}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Mileage">
              <span className="font-semibold">
                {data.asset.vehicle_info.mileage}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Daily Cost">
              <span className="font-semibold">
                {data.asset.vehicle_info.daily_cost}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Loan Amount">
              <span className="font-semibold">
                {data.asset.vehicle_info.loan_amount}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Monthly Loan Payment">
              <span className="font-semibold">
                {data.asset.vehicle_info.monthly_loan_payment}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Monthly Insurance Payment">
              <span className="font-semibold">
                {data.asset.vehicle_info.monthly_insurance_payment}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Last Maintenance">
              <span className="font-semibold">
                {data.asset.vehicle_info.last_maintenance
                  ? format(
                      new Date(data.asset.vehicle_info.last_maintenance),
                      "MM-dd-yyyy"
                    )
                  : "N/A"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Next Maintenance">
              <span className="font-semibold">
                {data.asset.vehicle_info.next_maintenance
                  ? format(
                      new Date(data.asset.vehicle_info.next_maintenance),
                      "MM-dd-yyyy"
                    )
                  : "N/A"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Others">
              <Checkbox checked={data.asset.vehicle_info.oil_change}>
                Oil Changed
              </Checkbox>
              <Checkbox checked={data.asset.vehicle_info.tire_rotation}>
                Tires Rotated
              </Checkbox>
              <Checkbox checked={data.asset.vehicle_info.tire_change}>
                Tire Changed
              </Checkbox>
            </Descriptions.Item>
          </>
        )}
        {data?.asset?.type?.includes("Property") && (
          <>
            <Descriptions.Item label="Name">
              <span className="font-semibold">
                {data.asset.warehouse_info.name}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Location">
              <span className="font-semibold">
                {data.asset.warehouse_info.location}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="City">
              <span className="font-semibold">
                {data.asset.warehouse_info.city}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="State">
              <span className="font-semibold">
                {data.asset.warehouse_info.state}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Zip">
              <span className="font-semibold">
                {data.asset.warehouse_info.zip}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Monthly Rent/Mortgage">
              <span className="font-semibold">
                {data.asset.warehouse_info.monthly_rent}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Monthly Insurance Payment">
              <span className="font-semibold">
                {data.asset.warehouse_info.monthly_insurance_payment}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Monthly Utility Cost">
              <span className="font-semibold">
                {data.asset.warehouse_info.monthly_utility_cost}
              </span>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </main>
  );
};

export default AssetDetails;
