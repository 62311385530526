import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import EmployeesList from "../Components/Employees/EmployeesList";
import { useSelector } from "react-redux";

const Employees = () => {
  const auth = useSelector((state) => state.auth);

  // Instance methods
  const navigate = useNavigate();

  return (
    <main className="mt-14 p-2 md:p-4 ">
      {auth.userData.role === "admin" && (
        <section className="text-center mt-4 md:mt-6">
          <Button
            className="bg-green-500 flex items-center mx-auto gap-x-1"
            type="primary"
            size="large"
            onClick={() => navigate("/create-employee")}
          >
            <PlusIcon className="w-5 h-5" />
            <span>Create Employee</span>
          </Button>
        </section>
      )}

      <EmployeesList />
    </main>
  );
};

export default Employees;
