import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Table,
} from "antd";
import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { format, differenceInDays } from "date-fns";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditProjectMutation,
  useGetContractorsQuery,
  useGetProjectQuery,
} from "../app/features/api/projectsApiSlice";
import {
  useAddContractorCostMutation,
  useAddInvoiceMutation,
  useGetContractorCostsQuery,
  useGetInvoicesQuery,
} from "../app/features/api/billingApiSlice";

const BillingDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetProjectQuery(params.projectId);
  const { data: contractorsData, isLoading: isContractorsDataLoading } =
    useGetContractorsQuery();
  const { data: contractorCostsData } = useGetContractorCostsQuery(
    params.projectId
  );
  const { data: invoicesData } = useGetInvoicesQuery(params.projectId);

  const [editProject, editResult] = useEditProjectMutation();
  const [addContractorCost, addContractorCostResult] =
    useAddContractorCostMutation();
  const [addInvoice, addInvoiceResult] = useAddInvoiceMutation();

  const [newCost, setNewCost] = useState(null);
  const [note, setNote] = useState(null);

  // contractor states
  const [isAddContractorModalVisible, setIsAddContractorModalVisible] =
    useState(false);
  const [contractorInvoicedAmount, setContractorInvoicedAmount] = useState("");
  const [contractorJobCompleted, setContractorJobCompleted] = useState(false);
  const [contractorInvoiceDate, setContractorInvoiceDate] = useState(null);

  const [contractorTask, setContractorTask] = useState(null);
  const [contractor, setContractor] = useState(null);
  const [contractorInvoiceNumber, setContractorInvoiceNumber] = useState("");

  // invoice
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const [invoiceTask, setInvoiceTask] = useState("");

  let costToDate;
  let totalProgress;
  let projectTotalAdditionalCost;

  let totalContractorCost = 0;
  let totalInvoiceAmount = 0;

  if (contractorCostsData?.contractorCosts?.length > 0) {
    totalContractorCost = contractorCostsData.contractorCosts.reduce(
      (acc, curr) => {
        return acc + curr.invoice_amount;
      },
      0
    );
  }

  if (invoicesData?.invoices?.length > 0) {
    totalInvoiceAmount = invoicesData.invoices.reduce((acc, curr) => {
      return acc + curr.invoice_amount;
    }, 0);
  }

  if (data) {
    const { project } = data;
    const { tasks } = data;

    const totalTaskCost = tasks.reduce((acc, curr) => {
      return acc + curr.actual_cost;
    }, 0);

    projectTotalAdditionalCost = project.additional_cost.reduce((acc, curr) => {
      if (curr.amount) return acc + Number(curr.amount);
      return acc + 0;
    }, 0);

    costToDate = totalTaskCost + projectTotalAdditionalCost;

    const totalTaskDuration = tasks.reduce((acc, curr) => {
      if (curr.estimated_end_date || curr.estimated_start_date)
        return (
          acc +
          differenceInDays(
            new Date(curr.estimated_end_date),
            new Date(curr.estimated_start_date)
          )
        );

      return acc + 0;
    }, 0);

    const totalDaysCount = tasks.reduce((acc, curr) => {
      return acc + curr.days_count;
    }, 0);

    if (totalDaysCount === 0) {
      totalProgress = 0;
    } else {
      totalProgress = (totalDaysCount / totalTaskDuration) * 100;
    }
  }

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  // Handles
  const handleEditProject = (payload) => {
    if (!params.projectId) {
      message.error("Please try again!");
      return;
    }

    editProject({ project_id: params.projectId, ...payload }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("Information updated successfully!");
        setNewCost(null);
        setNote(null);
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4">
      <Button
        className="flex items-center gap-x-1 my-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>
      <section>
        <Descriptions title="Project Info" bordered>
          <Descriptions.Item label="Name">
            <span className="font-semibold">{data.project.name}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Progress">
            <span className="font-semibold">{`${totalProgress} %`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            {data.project.status === "In Progress" && (
              <Badge status="processing" text={data.project.status} />
            )}
            {data.project.status === "Completed" && (
              <Badge status="success" text={data.project.status} />
            )}
            {data.project.status === "Cancelled" && (
              <Badge status="error" text={data.project.status} />
            )}
            {(data.project.status === "On Hold" ||
              data.project.status === "Not Started") && (
              <Badge status="default" text={data.project.status} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Start Date">
            <span className="font-semibold">
              {format(new Date(data.project.start_date), "MM-dd-yyyy")}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Estimated End Date" span={2}>
            <span className="font-semibold">
              {format(new Date(data.project.end_date), "MM-dd-yyyy")}
            </span>
          </Descriptions.Item>

          <Descriptions.Item label="Cost To Date">
            <span className="font-semibold">{`$${
              costToDate + totalContractorCost
            }`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Total Additional Cost">
            <span className="font-semibold">{`$${projectTotalAdditionalCost}`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Total Invoiced Amount">
            <span className="font-semibold">{`$${totalInvoiceAmount}`}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Profit">
            <span className="font-semibold">{`$${
              totalInvoiceAmount - (costToDate + totalContractorCost)
            }`}</span>
          </Descriptions.Item>
        </Descriptions>
      </section>

      <section className="mt-10">
        <div>
          <p className="text-center text-slate-500 text-lg my-3">
            Additional Costs
          </p>
          <div className="gap-x-4 flex items-center justify-around">
            <div className="w-[400px]">
              <Input
                placeholder="Enter amount"
                type="number"
                value={newCost}
                onChange={(e) => setNewCost(e.target.value)}
              />
              <Input.TextArea
                placeholder="Enter associated note"
                className="mt-3"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Button
                className="mt-3"
                onClick={() => {
                  if (!newCost || !note) {
                    message.error("Please fill all fields!");
                    return;
                  }
                  handleEditProject({
                    new_additional_cost: {
                      amount: newCost,
                      note,
                    },
                  });
                }}
              >
                Add To Additional Cost
              </Button>
            </div>

            <div className="w-[400px] ">
              {data.project.additional_cost.length !== 0 && (
                <table>
                  <thead>
                    <th>Amount</th>
                    <th>Note</th>
                  </thead>
                  <tbody>
                    {data.project.additional_cost.map((cost, index) => (
                      <tr key={index}>
                        <td>{cost.amount}</td>
                        <td>{cost.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className="flex-grow px-4">
          <p className="text-center text-slate-500 text-lg my-3">
            Contractor Invoices
          </p>
          <div className="flex justify-center items-center gap-x-6">
            <Button
              className="bg-green-500 flex items-center gap-x-1"
              type="primary"
              onClick={() => {
                setIsAddContractorModalVisible(true);
              }}
            >
              <PlusIcon className="w-5 h-5" />
              <span>Add</span>
            </Button>
            <Button
              onClick={() => {
                navigate("/contractors");
              }}
            >
              View Contractors List
            </Button>
          </div>
          <div className="mt-3">
            <Table
              columns={[
                {
                  title: "Name",
                  dataIndex: "contractor_name",
                  key: "contractor_name",
                },
                {
                  title: "Invoice Number",
                  dataIndex: "invoice_number",
                  key: "invoice_number",
                },
                {
                  title: "Invoice Amount",
                  dataIndex: "invoice_amount",
                  key: "invoice_amount",
                  render: (text) => <span>{text || "N/A"}</span>,
                },
                {
                  title: "Invoice Date",
                  dataIndex: "invoice_date",
                  key: "invoice_date",
                  render: (text) => (
                    <span>
                      {text
                        ? `${new Date(text).getMonth() + 1}-${
                            new Date(text).getDate() < 10
                              ? `0${new Date(text).getDate()}`
                              : new Date(text).getDate()
                          }-${new Date(text).getFullYear()}`
                        : "N/A"}
                    </span>
                  ),
                },
                {
                  title: "Task Name",
                  dataIndex: "task_name",
                  key: "task_name",
                },
                {
                  title: "Job Completed",
                  dataIndex: "job_completed",
                  key: "job_completed",
                  render: (text) => (
                    <span>{<Checkbox checked={text}></Checkbox>}</span>
                  ),
                },
              ]}
              dataSource={contractorCostsData?.contractorCosts || []}
              pagination={false}
            />
          </div>
        </div>

        <div className="flex-grow px-4 mb-6">
          <p className="text-center text-slate-500 text-lg my-3">Invoices</p>
          <div className="flex justify-center items-center gap-x-6">
            <Button
              className="bg-green-500 flex items-center gap-x-1"
              type="primary"
              onClick={() => {
                setIsInvoiceModalVisible(true);
              }}
            >
              <PlusIcon className="w-5 h-5" />
              <span>Add</span>
            </Button>
          </div>
          <div className="mt-3">
            <Table
              columns={[
                {
                  title: "Invoice Number",
                  dataIndex: "invoice_number",
                  key: "invoice_number",
                },
                {
                  title: "Invoice Amount",
                  dataIndex: "invoice_amount",
                  key: "invoice_amount",
                  render: (text) => <span>{text || "N/A"}</span>,
                },
                {
                  title: "Invoice Date",
                  dataIndex: "invoice_date",
                  key: "invoice_date",
                  render: (text) => (
                    <span>
                      {text
                        ? `${new Date(text).getMonth() + 1}-${
                            new Date(text).getDate() < 10
                              ? `0${new Date(text).getDate()}`
                              : new Date(text).getDate()
                          }-${new Date(text).getFullYear()}`
                        : "N/A"}
                    </span>
                  ),
                },
                {
                  title: "Task Name",
                  dataIndex: "task_name",
                  key: "task_name",
                },
                {
                  title: "Type",
                  dataIndex: "type",
                  key: "type",
                },
              ]}
              dataSource={invoicesData?.invoices || []}
              pagination={false}
            />
          </div>
        </div>
      </section>

      {/* add invoice modal */}
      <Modal
        open={isInvoiceModalVisible}
        title="Add Invoice"
        onOk={() => {}}
        onCancel={() => setIsInvoiceModalVisible((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setIsInvoiceModalVisible((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addInvoiceResult.isLoading}
            onClick={() => {
              const task_name = data.tasks.find(
                (task) => task._id === invoiceTask
              ).name;

              addInvoice({
                project_id: params.projectId,
                porject_name: data.project.name,
                task_id: invoiceTask,
                task_name,
                type: invoiceType,
                invoice_number: invoiceNumber,
                invoice_amount: Number(invoiceAmount),
                invoice_date: invoiceDate,
              }).then((res) => {
                if (res.data.success) {
                  message.success("Invoice record added successfully!");
                  setIsInvoiceModalVisible(false);
                } else {
                  message.error("Something went wrong!");
                }
              });
            }}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Select
          placeholder="Select task"
          className="my-2"
          style={{
            width: "40%",
          }}
          options={data.tasks?.map((task) => ({
            label: task.name,
            value: task._id,
          }))}
          onChange={(value) => {
            setInvoiceTask(value);
          }}
        />
        <Input
          className="my-2"
          placeholder="Enter invoice number"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
        />
        <div className="flex items-center justify-between">
          <Input
            placeholder="Enter invoiced amount"
            className="my-2"
            type="number"
            style={{
              width: "50%",
            }}
            value={invoiceAmount}
            onChange={(e) => setInvoiceAmount(e.target.value)}
          />
          <Select
            placeholder="Select type"
            options={[
              {
                label: "Partial",
                value: "Partial",
              },
              {
                label: "Final",
                value: "Final",
              },
            ]}
            onChange={(value) => {
              setInvoiceType(value);
            }}
          />
        </div>
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Invoice date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setInvoiceDate(dateStr)}
        />
      </Modal>

      {/* add contractor modal */}
      <Modal
        open={isAddContractorModalVisible}
        title="Add Contractor Invoice"
        onOk={() => {}}
        onCancel={() => setIsAddContractorModalVisible((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setIsAddContractorModalVisible((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addContractorCostResult.isLoading}
            onClick={() => {
              const task_name = data.tasks.find(
                (task) => task._id === contractorTask
              ).name;
              const contractor_name = contractorsData.contractors.find(
                (c) => c._id === contractor
              ).name;

              addContractorCost({
                project_id: params.projectId,
                porject_name: data.project.name,
                invoice_number: contractorInvoiceNumber,
                task_id: contractorTask,
                task_name,
                contractor_id: contractor,
                contractor_name,
                invoice_date: contractorInvoiceDate,
                invoice_amount: Number(contractorInvoicedAmount),
                job_completed: contractorJobCompleted,
              }).then((res) => {
                if (res.data.success) {
                  message.success("Contractor added successfully!");
                  setIsAddContractorModalVisible((prev) => !prev);
                  setContractorInvoiceNumber("");
                  setContractorInvoiceDate("");
                  setContractorInvoicedAmount("");
                  setContractorJobCompleted(false);
                  setContractor("");
                  setContractorTask("");
                } else {
                  message.error("Something went wrong!");
                }
              });
            }}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <div className="flex justify-between mt-3 mb-2">
          <Select
            placeholder="Select contractor"
            style={{
              width: "40%",
            }}
            options={contractorsData?.contractors?.map((contractor) => ({
              label: contractor.name,
              value: contractor._id,
            }))}
            onChange={(value) => {
              setContractor(value);
            }}
          />
          <Select
            placeholder="Select task"
            style={{
              width: "40%",
            }}
            options={data.tasks?.map((task) => ({
              label: task.name,
              value: task._id,
            }))}
            onChange={(value) => {
              setContractorTask(value);
            }}
          />
        </div>
        <Input
          className="my-2"
          placeholder="Enter invoice number"
          value={contractorInvoiceNumber}
          onChange={(e) => setContractorInvoiceNumber(e.target.value)}
        />
        <div className="flex items-center justify-between">
          <Input
            placeholder="Enter invoiced amount"
            className="my-2"
            type="number"
            style={{
              width: "50%",
            }}
            value={contractorInvoicedAmount}
            onChange={(e) => setContractorInvoicedAmount(e.target.value)}
          />
          <Checkbox
            checked={contractorJobCompleted}
            onChange={(e) => setContractorJobCompleted(e.target.checked)}
          >
            Job completed
          </Checkbox>
        </div>
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Invoice date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setContractorInvoiceDate(dateStr)}
        />
      </Modal>
    </main>
  );
};

export default BillingDetails;
