import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import { authApiSlice } from "./features/api/authApiSlice";
import { employeesApiSlice } from "./features/api/employeesApiSlice";
import { projectsApiSlice } from "./features/api/projectsApiSlice";
import { attendanceApiSlice } from "./features/api/attendanceApiSlice";
import { assetsApiSlice } from "./features/api/assetsApiSlice";
import { billingApiSlice } from "./features/api/billingApiSlice";

export const store = configureStore({
  reducer: {
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [employeesApiSlice.reducerPath]: employeesApiSlice.reducer,
    [projectsApiSlice.reducerPath]: projectsApiSlice.reducer,
    [attendanceApiSlice.reducerPath]: attendanceApiSlice.reducer,
    [assetsApiSlice.reducerPath]: assetsApiSlice.reducer,
    [billingApiSlice.reducerPath]: billingApiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApiSlice.middleware)
      .concat(employeesApiSlice.middleware)
      .concat(projectsApiSlice.middleware)
      .concat(attendanceApiSlice.middleware)
      .concat(assetsApiSlice.middleware)
      .concat(billingApiSlice.middleware),
});
