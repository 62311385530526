import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectDetailsChart = ({ data }) => {
  return (
    <div className="w-80 h-80">
      <Doughnut data={data} />
    </div>
  );
};

export default ProjectDetailsChart;
