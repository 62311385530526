import { useSelector } from "react-redux";
import { Button, Checkbox, message, Popconfirm, Spin } from "antd";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
} from "../../app/features/api/employeesApiSlice";
import { useNavigate } from "react-router-dom";

const EmployeesList = () => {
  const auth = useSelector((state) => state.auth);
  const { data, isLoading, isError } = useGetEmployeesQuery();
  const [deleteEmployee, deleteResult] = useDeleteEmployeeMutation();

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  // Handles
  const handleDeleteEmployee = (employeeId) => {
    deleteEmployee({ id: employeeId }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("Employee deleted successfully!");
      }
    });
  };

  return (
    <section className="mt-4 md:mt-6">
      <table>
        <thead>
          <th>Name</th>
          <th>Date of Birth</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Position</th>
          {auth.userData?.role === "admin" && (
            <>
              <th>Daily Wage</th>
              <th>Commission</th>
            </>
          )}
          <th>Overhead Employee</th>
          <th>Join Date</th>
          <th>Termination Date</th>
          {auth.userData?.role === "admin" && <th>Action</th>}
        </thead>
        <tbody>
          {data.employees.map((employee) => (
            <tr key={employee._id}>
              <td>{`${employee.first_name} ${
                employee.middle_name ? employee.middle_name : ""
              } ${employee.last_name ? employee.last_name : ""}`}</td>
              <td>
                {employee.date_of_birth
                  ? `${new Date(employee.date_of_birth).getMonth() + 1}-${
                      new Date(employee.date_of_birth).getDate() < 10
                        ? `0${new Date(employee.date_of_birth).getDate()}`
                        : new Date(employee.date_of_birth).getDate()
                    }-${new Date(employee.date_of_birth).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {employee.phone
                  ? `+1 (${employee.phone.slice(0, 3)}) ${employee.phone.slice(
                      3,
                      employee.phone.length
                    )}`
                  : "N/A"}
              </td>
              <td>
                <div>
                  <p>
                    <span className="text-slate-500">Address: </span>
                    <span className="text-slate-900">
                      {employee.home_address.street}
                    </span>
                  </p>
                  <p className="my-1">
                    <span className="text-slate-500">City: </span>
                    <span>{employee.home_address.city}</span>
                  </p>
                  <p className="my-1">
                    <span className="text-slate-500">State: </span>
                    <span>{employee.home_address.state}</span>
                  </p>
                  <p>
                    <span className="text-slate-500">Zip: </span>
                    <span>{employee.home_address.zip}</span>
                  </p>
                </div>
              </td>
              <td>{employee.position}</td>
              {auth.userData?.role === "admin" && (
                <>
                  <td>{employee.daily_wage}</td>
                  <td>{employee.commission || "N/A"}</td>
                </>
              )}
              <td>
                <Checkbox checked={employee?.overhead}></Checkbox>
              </td>
              <td>
                {employee.join_date
                  ? `${new Date(employee.join_date).getMonth() + 1}-${
                      new Date(employee.join_date).getDate() < 10
                        ? `0${new Date(employee.join_date).getDate()}`
                        : new Date(employee.join_date).getDate()
                    }-${new Date(employee.join_date).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {employee.termination_date
                  ? `${new Date(employee.termination_date).getMonth() + 1}-${
                      new Date(employee.termination_date).getDate() < 10
                        ? `0${new Date(employee.termination_date).getDate()}`
                        : new Date(employee.termination_date).getDate()
                    }-${new Date(employee.termination_date).getFullYear()}`
                  : "N/A"}
              </td>
              {auth.userData?.role === "admin" && (
                <td>
                  <div className="flex items-center gap-x-2">
                    <Button
                      type="primary"
                      className="bg-green-500"
                      onClick={() => {
                        navigate(`/edit-employee/${employee._id}`);
                      }}
                    >
                      <PencilIcon className="w-5 h-5" />
                    </Button>
                    <Popconfirm
                      title={`Are you sure to remove ${employee.first_name}?`}
                      onConfirm={() => handleDeleteEmployee(employee._id)}
                      okText="Yes"
                      cancelText="No"
                      okButtonProps={
                        ({ danger: true }, { loading: deleteResult.isLoading })
                      }
                    >
                      <Button danger>
                        <TrashIcon className="w-5 h-5" />
                      </Button>
                    </Popconfirm>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default EmployeesList;
