import { Button, DatePicker, Input, message, Modal, Select } from "antd";
import { useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useCreateProjectMutation } from "../app/features/api/projectsApiSlice";
import ProjectsList from "../Components/Projects/ProjectsList";
import { useSelector } from "react-redux";

const Projects = () => {
  const auth = useSelector((state) => state.auth);
  const [createProject, result] = useCreateProjectMutation();

  // Local states
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [region, setRegion] = useState(null);
  const [totalCmh, setTotalCmh] = useState(null);
  const [estimatedStartDate, setEstimatedStartDate] = useState(null);
  const [wr, setWr] = useState(null);

  // Handles
  const handleCreateProject = () => {
    let fullName;
    if (wr && name) {
      fullName = `${name} - ${wr}`;
    }

    if (!name && !fullName) {
      message.error("Name is required!");
      return;
    }

    createProject({
      name: fullName || name,
      start_date: startDate,
      end_date: endDate,
      region,
      total_cmh: totalCmh,
      estimated_start_date: estimatedStartDate,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setCreateModalOpen((prev) => !prev);
        message.success("Project added successfully!");
        setName("");
        setStartDate("");
        setEndDate("");
        setEstimatedStartDate("");
        setRegion("");
        setWr("");
        setTotalCmh("");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4 ">
      {auth.userData.role === "admin" && (
        <section className="text-center mt-4 md:mt-6">
          <Button
            className="bg-green-500 flex items-center mx-auto gap-x-1"
            type="primary"
            size="large"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            <PlusIcon className="w-5 h-5" />
            <span>Add Project</span>
          </Button>
        </section>
      )}

      <ProjectsList />

      <Modal
        open={createModalOpen}
        title="Add Project"
        onOk={handleCreateProject}
        onCancel={() => setCreateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={result.isLoading}
            onClick={handleCreateProject}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Enter WR number"
          className="my-2"
          value={wr}
          onChange={(e) => setWr(e.target.value)}
        />

        <Select
          placeholder="Select region"
          style={{
            width: 200,
          }}
          options={[
            {
              value: "North",
              label: "North",
            },
            {
              value: "East",
              label: "East",
            },
            {
              value: "Broward",
              label: "Broward",
            },
          ]}
          className="my-2"
          onChange={(value) => setRegion(value)}
        />
        <Input
          placeholder="Enter total CMH"
          className="my-2"
          type="number"
          value={totalCmh}
          onChange={(e) => setTotalCmh(e.target.value)}
        />

        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Estimated Start Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setEstimatedStartDate(dateStr)}
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Start Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setStartDate(dateStr)}
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Estimated end Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setEndDate(dateStr)}
        />
      </Modal>
    </main>
  );
};

export default Projects;
