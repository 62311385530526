import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, message, Popconfirm, Spin } from "antd";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  useDeleteAssetMutation,
  useGetAssetsQuery,
} from "../../app/features/api/assetsApiSlice";

const AssetsList = () => {
  const { data, isLoading, isError, isFetching } = useGetAssetsQuery();
  const [deleteAsset] = useDeleteAssetMutation();

  const navigate = useNavigate();

  if (isLoading || isFetching)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  const handleDeleteAsset = (passedId) => {
    deleteAsset({ id: passedId }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("Asset deleted successfully!");
      }
    });
  };

  return (
    <section className="mt-4 md:mt-6">
      <table>
        <thead>
          <th>Tag</th>
          <th>Name</th>
          <th>Type</th>
          <th>Assigned To</th>
          <th>Tag Expiration</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {data?.assets?.map((asset) => (
            <tr key={asset._id}>
              <td
                className="text-primary cursor-pointer"
                onClick={() => navigate(`/asset-details/${asset._id}`)}
              >
                {asset.tag}
              </td>
              <td>
                {asset.warehouse_info
                  ? asset.warehouse_info.name
                  : asset.vehicle_info.name || "N/A"}
              </td>
              <td>{asset.type || "N/A"}</td>
              <td>{asset.assigned_to || "N/A"}</td>
              <td>
                {asset.tag_expiration
                  ? format(new Date(asset.tag_expiration), "MM-dd-yyyy")
                  : "N/A"}
              </td>
              <td>
                <div className="flex items-center gap-x-2">
                  <Button
                    type="primary"
                    className="bg-green-500"
                    onClick={() => {
                      navigate(`/edit-asset/${asset._id}`);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                  </Button>
                  <Popconfirm
                    title={`Are you sure to delete records for ${asset.tag}?`}
                    onConfirm={() => {
                      handleDeleteAsset(asset._id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger>
                      <TrashIcon className="w-5 h-5" />
                    </Button>
                  </Popconfirm>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default AssetsList;
