import { Button, Input, message, Modal, Select } from "antd";
import { useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import ManagerList from "../Components/Managers/ManagerList";
import { useCreateUserMutation } from "../app/features/api/authApiSlice";

const Managers = () => {
  const [createUser, result] = useCreateUserMutation();

  // Local states
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("manager");
  const [password, setPassword] = useState("");

  // Handles
  const handleCreateUser = () => {
    if (!username || !password) {
      message.error("Please enter username and password!");
      return;
    }
    if (password.length < 4) {
      message.error("Password must be atleast 4 characters!");
      return;
    }

    createUser({
      username,
      password,
      role,
      name: name || "Guest",
      authorized_projects: [],
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setCreateModalOpen((prev) => !prev);
        message.success("Manager added successfully!");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4 max-w-[1700px] mx-auto">
      <section className="text-center mt-4 md:mt-6">
        <Button
          className="bg-green-500 flex items-center mx-auto gap-x-1"
          type="primary"
          size="large"
          onClick={() => setCreateModalOpen((prev) => !prev)}
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add User</span>
        </Button>
      </section>

      <ManagerList />

      <Modal
        open={createModalOpen}
        title="Add User"
        onOk={handleCreateUser}
        onCancel={() => setCreateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={result.isLoading}
            onClick={handleCreateUser}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter username (unique)"
          className="mt-3 mb-2"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Enter password"
          className="mt-3 mb-4"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Select
          style={{ width: 120 }}
          value={role}
          onChange={(value) => setRole(value)}
          className="my-2"
          options={[
            { label: "Admin", value: "admin" },
            { label: "Manager", value: "manager" },
          ]}
        />
      </Modal>
    </main>
  );
};

export default Managers;
