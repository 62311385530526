import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useGetEmployeesQuery } from "../app/features/api/employeesApiSlice";
import {
  useEditAssetMutation,
  useGetAssetByIdQuery,
} from "../app/features/api/assetsApiSlice";

const EditAsset = () => {
  const { assetId } = useParams();
  const {
    data: assetData,
    isLoading: isAssetDataLoading,
    isError,
  } = useGetAssetByIdQuery(assetId);
  const { data } = useGetEmployeesQuery();
  const [editAsset, result] = useEditAssetMutation();

  const [form] = Form.useForm();

  const navigate = useNavigate();

  if (isAssetDataLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const onFinish = (values) => {
    let data;
    if (assetData?.asset?.type?.includes("Vehicle")) {
      let {
        tag_expiration,
        last_maintenance,
        next_maintenance,
        tag,
        assigned_to,
        name,
        monthly_loan_payment,
        monthly_insurance_payment,
        mileage,
        loan_amount,
        daily_cost,
      } = values;

      tag_expiration = tag_expiration?.format("MM-DD-YYYY") || undefined;
      last_maintenance = last_maintenance?.format("MM-DD-YYYY") || undefined;
      next_maintenance = next_maintenance?.format("MM-DD-YYYY") || undefined;

      data = {
        tag: tag || assetData.asset.tag,
        type: assetData.asset.type,
        tag_expiration: tag_expiration || assetData.asset.tag_expiration,
        assigned_to: assigned_to || assetData.asset.assigned_to,
        vehicle_info: {
          name: name || assetData.asset.vehicle_info.name,
          monthly_loan_payment:
            monthly_loan_payment ||
            assetData.asset.vehicle_info.monthly_loan_payment,
          monthly_insurance_payment:
            monthly_insurance_payment ||
            assetData.asset.vehicle_info.monthly_insurance_payment,
          mileage: mileage || assetData.asset.vehicle_info.mileage,
          loan_amount: loan_amount || assetData.asset.vehicle_info.loan_amount,
          daily_cost: daily_cost || assetData.asset.vehicle_info.daily_cost,
          tag_expiration:
            tag_expiration || assetData.asset.vehicle_info.tag_expiration,
          last_maintenance:
            last_maintenance || assetData.asset.vehicle_info.last_maintenance,
          next_maintenance:
            next_maintenance || assetData.asset.vehicle_info.next_maintenance,
        },
      };
    }

    if (assetData?.asset?.type?.includes("Warehouse")) {
      let {
        tag_expiration,
        tag,
        assigned_to,
        type,
        name,
        location,
        monthly_rent,
        monthly_insurance_payment,
        monthly_utility_cost,
      } = values;

      tag_expiration = tag_expiration?.format("MM-DD-YYYY") || undefined;

      data = {
        tag: tag || assetData.asset.tag,
        type: type || assetData.asset.type,
        tag_expiration: tag_expiration || assetData.asset.tag_expiration,
        assigned_to: assigned_to || assetData.asset.assigned_to,
        warehouse_info: {
          name: name || assetData.asset.warehouse_info.name,
          location: location || assetData.asset.warehouse_info.location,
          monthly_rent:
            monthly_rent || assetData.asset.warehouse_info.monthly_rent,
          monthly_insurance_payment:
            monthly_insurance_payment ||
            assetData.asset.warehouse_info.monthly_insurance_payment,
          monthly_utility_cost:
            monthly_utility_cost ||
            assetData.asset.warehouse_info.monthly_utility_cost,
        },
      };
    }

    editAsset({
      id: assetId,
      ...data,
    }).then((res) => {
      if (res.data.success) {
        message.success("Asset updated successfully!");
        navigate("/assets");
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4 ">
      <Button
        className="flex items-center gap-x-1 ml-4"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>

      <div className="max-w-5xl mt-4 ">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="tag"
            label="Tag"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tag_expiration"
            label="Tag Expiration Date"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Tag expiration date (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="assigned_to"
            label="Assigned To"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Select
              options={
                data
                  ? data?.employees?.map((employee) => {
                      return {
                        label: employee.first_name,
                        value: employee.first_name,
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <div>
            {assetData?.asset?.type?.includes("Vehicle") && (
              <>
                <Form.Item
                  name="mileage"
                  label="Mileage"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="daily_cost"
                  label="Daily Cost"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="loan_amount"
                  label="Loan Amount"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_loan_payment"
                  label="Monthly Loan Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_insurance_payment"
                  label="Monthly Insurance Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="last_maintenance"
                  label="Last Maintenance"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Last maintenance date (MM-DD-YYYY)"
                  />
                </Form.Item>

                <Form.Item
                  name="next_maintenance"
                  label="Next Maintenance"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Next maintenance date (MM-DD-YYYY)"
                  />
                </Form.Item>
              </>
            )}
          </div>

          <div>
            {assetData?.asset?.type?.includes("Warehouse") && (
              <>
                <Form.Item
                  name="location"
                  label="Location"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="monthly_rent"
                  label="Monthly Rent/Mortgage"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_insurance_payment"
                  label="Monthly Insurance Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_utility_cost"
                  label="Monthly Utility Cost"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </>
            )}
          </div>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-green-500"
              loading={result.isLoading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default EditAsset;
