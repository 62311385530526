import { format } from "date-fns";
import "jspdf-autotable";
import { Card, Spin, Statistic } from "antd";
import { useGetProjectsQuery } from "../app/features/api/projectsApiSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const auth = useSelector((state) => state.auth);
  const { data: projectsData, isLoading: isProjectsDataLoading } =
    useGetProjectsQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.userData?.role === "manager") {
      navigate("/attendance");
    }
  }, [auth?.userData?.role, navigate]);

  if (isProjectsDataLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  const inProgressProjects = projectsData.projects.filter(
    (project) => project.status === "In Progress"
  );

  const cancelledProjects = projectsData.projects.filter(
    (project) => project.status === "Cancelled"
  );

  const onHoldProjects = projectsData.projects.filter(
    (project) => project.status === "On Hold"
  );

  const notStartedProjects = projectsData.projects.filter(
    (project) => project.status === "Not Started"
  );

  const completedProjects = projectsData.projects.filter(
    (project) => project.status === "Completed"
  );

  return (
    <main className="mt-14 p-2 md:p-4 max-w-[1700px] mx-auto">
      <div className="flex items-center justify-center my-3 gap-x-2 text-lg">
        <span className="font-bold">{format(new Date(), "p")}</span>
        <span>{format(new Date(), "PPPP")}</span>
      </div>
      {auth?.userData?.role === "admin" && (
        <section>
          <div className="flex gap-x-4 w-[830px] mx-auto lg:mt-6 xl:mt-14">
            <Card className="w-40">
              <Statistic
                title="Total"
                value={
                  inProgressProjects.length +
                  cancelledProjects.length +
                  onHoldProjects.length +
                  notStartedProjects.length +
                  completedProjects.length
                }
                valueStyle={{ color: "#06b6d4" }}
              />
            </Card>
            <Card className="w-40">
              <Statistic
                title="In Progress"
                value={inProgressProjects.length}
                valueStyle={{ color: "#06b6d4" }}
              />
            </Card>
            <Card className="w-40">
              <Statistic
                title="Not Started"
                value={notStartedProjects.length}
                valueStyle={{ color: "#06b6d4" }}
              />
            </Card>
            <Card className="w-40">
              <Statistic
                title="On Hold"
                value={onHoldProjects.length}
                valueStyle={{ color: "#06b6d4" }}
              />
            </Card>
            <Card className="w-40">
              <Statistic
                title="Cancelled"
                value={cancelledProjects.length}
                valueStyle={{ color: "#06b6d4" }}
              />
            </Card>
          </div>
        </section>
      )}
    </main>
  );
};

export default Dashboard;
