import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { jsPDF } from "jspdf";
import { format, startOfMonth, getMonth, getDay } from "date-fns";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import {
  useCreateAttendanceMutation,
  useGetAttendancesQuery,
} from "../app/features/api/attendanceApiSlice";
import { useGetEmployeesQuery } from "../app/features/api/employeesApiSlice";
import { useGetProjectsQuery } from "../app/features/api/projectsApiSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useHorizontalScroll } from "../utils/hooks/useSideScroll";
import { useSelector } from "react-redux";

// format MM-dd-yyyy
const formatDay = (day) => {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      return "";
  }
};

const getRecordNumber = () => {
  const month = getMonth(new Date());
  switch (month) {
    case 0:
      return 31;
    case 1:
      return 28;
    case 2:
      return 31;
    case 3:
      return 30;
    case 4:
      return 31;
    case 5:
      return 30;
    case 6:
      return 31;
    case 7:
      return 31;
    case 8:
      return 30;
    case 9:
      return 31;
    case 10:
      return 30;
    case 11:
      return 31;
    default:
      return 0;
  }
};

const getDates = () => {
  const start = startOfMonth(new Date());
  const temp = [...Array(getRecordNumber()).keys()].map(() => {
    const date = format(start, "MM-dd-yyyy");
    start.setDate(start.getDate() + 1);
    return date;
  });

  return temp;
};

const Attendance = () => {
  const auth = useSelector((state) => state.auth);
  const [createAttendance, createResult] = useCreateAttendanceMutation();
  const { data: employeesData, isLoading: isEmployeesDataLoading } =
    useGetEmployeesQuery();
  const { data: projectsData } = useGetProjectsQuery();

  // payroll states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Local states
  const dates = getDates();
  const [month, setMonth] = useState(getMonth(new Date()));
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  const [extraModalVisible, setExtraModalVisible] = useState(false);
  const [feetWorked, setFeetWorked] = useState("");
  const [extraPayload, setExtraPayload] = useState(null);

  const { data, isLoading, isError, isFetching } = useGetAttendancesQuery({
    month,
    year,
  });

  const navigate = useNavigate();
  const scrollRef = useHorizontalScroll();
  const containerRef = useRef();

  const [options, setOptions] = useState([
    {
      label: "PL",
      value: "PL",
    },
    {
      label: "WO",
      value: "WO",
    },
    {
      label: "UL",
      value: "UL",
    },
  ]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [month, year]);

  useEffect(() => {
    if (
      projectsData &&
      options.length === 3 &&
      projectsData.projects.length !== 0
    ) {
      const initialoptions = [
        ...projectsData?.projects?.map((project) => ({
          value: project._id,
          label: project.name,
          isLeaf: false,
        })),
      ];
      setOptions((prev) => [...prev, ...initialoptions]);
    }
  }, [projectsData, options]);

  const handlePayroll = () => {
    const payroll = {
      year,
      month,
      from: startDate,
      to: endDate,
      records: [],
    };

    // calculate payroll
    data?.attendances?.forEach((attendance) => {
      const employee = employeesData?.employees.find(
        (employee) => employee._id === attendance.employee_id
      );
      if (!employee) return;

      const dailyWage = employee?.daily_wage;
      const commission = employee?.commission;

      const inRangerecords = attendance?.records.filter(
        (record) =>
          new Date(record.date) >= new Date(startDate) &&
          new Date(record.date) <= new Date(endDate)
      );

      console.log(inRangerecords);

      const presentRecords = inRangerecords.filter(
        (record) => record.attendance_status === "Present"
      );

      console.log(presentRecords);
      let total_commission = 0;

      if (commission) {
        total_commission = presentRecords.reduce((acc, record) => {
          return acc + Number(record?.total_footage) * commission;
        }, 0);
      }

      payroll.records.push({
        employee_name: employee?.first_name + " " + employee?.last_name,
        employee_position: employee?.position,
        days: presentRecords.length,
        total_wage: presentRecords.length * dailyWage + total_commission,
        total_commission: total_commission,
      });
    });

    console.log(payroll);

    // print payroll
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = [
      "Name",
      "Position",
      "Days Count",
      "Wage",
      "Commission",
      "Total",
    ];
    var rows = [];

    for (let i = 0; i < payroll.records.length; i++) {
      /*for (var key in json[i]) {
      var temp = [key, json[i][key]];
      rows.push(temp);
    }*/
      var temp = [
        payroll.records[i].employee_name,
        payroll.records[i].employee_position,
        payroll.records[i].days,
        payroll.records[i].total_wage,
        payroll.records[i].total_commission,
        payroll.records[i].total_wage + payroll.records[i].total_commission,
      ];
      rows.push(temp);
    }

    pdf.text(180, 40, `PAYROLL (${startDate} => ${endDate})`);

    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: "#fff",
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: "#000",
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    console.log(pdf.output("datauristring"));
    pdf.save(`payroll-${month}-${year}.pdf`);
  };

  if (loading || isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  const loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    // fetch selected project tasks
    if (projectsData) {
      const project = projectsData.projects.find(
        (project) => project._id === targetOption.value
      );
      const tasks = project.tasks.map((task) => ({
        value: task.id,
        label: task.name,
        isLeaf: true,
      }));
      targetOption.loading = false;
      targetOption.children = tasks;
      setOptions([...options]);
    }
  };

  const onChange = (value, selectedOptions, payload) => {
    createAttendance({
      month,
      year,
      employee_id: payload.employee_id,
      employee_name: payload.employee_name,
      employee_info: payload.employee_info,
      new_records: [
        {
          attendance_status: value.length === 1 ? value[0] : "Present",
          project_name: value.length === 1 ? "" : selectedOptions[0]?.label,
          project_id: value.length === 1 ? "" : selectedOptions[0]?.value,
          task_name: value.length === 1 ? "" : selectedOptions[1]?.label,
          task_id: value.length === 1 ? "" : selectedOptions[1]?.value,
          date: payload.date,
        },
      ],
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }
      if (res.data?.success) {
        message.success("Record added successfully!");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4">
      <section className="flex mt-4 md:mt-6 justify-around mb-8 ">
        <div>
          <Select
            defaultValue={month}
            style={{ width: 120 }}
            onChange={(value) => setMonth(value)}
            options={[
              {
                value: 0,
                label: "January",
              },
              {
                value: 1,
                label: "February",
              },
              {
                value: 2,
                label: "March",
              },
              {
                value: 3,
                label: "April",
              },
              {
                value: 4,
                label: "May",
              },
              {
                value: 5,
                label: "June",
              },
              {
                value: 6,
                label: "July",
              },
              {
                value: 7,
                label: "August",
              },
              {
                value: 8,
                label: "September",
              },
              {
                value: 9,
                label: "October",
              },
              {
                value: 10,
                label: "November",
              },
              {
                value: 11,
                label: "December",
              },
            ]}
          />

          <Select
            defaultValue={year}
            className="ml-2"
            style={{ width: 120 }}
            onChange={(value) => setYear(value)}
            options={[
              {
                value: 2022,
                label: 2022,
              },
              {
                value: 2023,
                label: 2023,
              },
              {
                value: 2024,
                label: 2024,
              },
              {
                value: 2025,
                label: 2025,
              },
              {
                value: 2026,
                label: 2022,
              },
            ]}
          />
        </div>
        <div className="flex justify-center items-center gap-x-3">
          <Button
            onClick={() => {
              containerRef.current.scrollLeft -=
                containerRef.current.clientWidth - 100;
            }}
          >
            Prev
          </Button>
          <Button
            onClick={() => {
              containerRef.current.scrollLeft +=
                containerRef.current.clientWidth - 100;
            }}
          >
            Next
          </Button>
          <Checkbox
            value={showScroll}
            onChange={(e) => {
              setShowScroll(e.target.checked);
            }}
          >
            Show Scrollbar
          </Checkbox>
        </div>
      </section>

      <section
        className={`mt-4 overflow-x-scroll py-4 px-2 ${
          showScroll ? "" : "scrollbar-hide"
        }`}
        ref={containerRef}
        id="container"
      >
        <table>
          <thead>
            <th>Name</th>
            {dates.map((date) => (
              <th key={date}>
                <div className="">
                  <div className="flex justify-start items-center gap-x-2">
                    <span>{formatDay(getDay(new Date(date)))}</span>
                    <span>{format(new Date(date), "MMM-dd")}</span>
                  </div>
                  <p>{date}</p>
                </div>
              </th>
            ))}
          </thead>
          <tbody>
            {employeesData?.employees?.map((employee) => {
              return (
                <tr key={employee._id}>
                  <td
                    className="cursor-pointer text-primary"
                    onClick={() => {
                      const attendance = data?.attendances.find(
                        (attendance) =>
                          attendance.employee_id === employee._id &&
                          attendance.month === month &&
                          attendance.year === year
                      );
                      if (!attendance)
                        return message.warning(
                          "No attendance record found for this employee!"
                        );
                      navigate(`/attendance-details/${attendance?._id}`);
                    }}
                  >
                    {`${employee.first_name} ${employee.last_name}`}
                  </td>
                  {dates.map((date) => {
                    const attendance = data?.attendances.find(
                      (attendance) =>
                        attendance.employee_id === employee._id &&
                        attendance.month === month &&
                        attendance.year === year
                    );

                    const record = attendance?.records.find(
                      (record) => record.date === date
                    );

                    const getDefaultValue = () => {
                      if (record) {
                        if (record.attendance_status === "Present") {
                          return [record.project_name, record.task_name];
                        } else {
                          return [record.attendance_status];
                        }
                      }
                      return undefined;
                    };
                    return (
                      <td className="">
                        <div className="flex items-center gap-x-2">
                          <Cascader
                            placeholder={
                              record ? record.attendance_status : "Select"
                            }
                            defaultValue={
                              getDefaultValue() ? getDefaultValue() : undefined
                            }
                            options={options}
                            style={{ width: 200 }}
                            loadData={loadData}
                            onChange={(value, selectedOptions) => {
                              const payload = {
                                employee_id: employee._id,
                                employee_name: `${employee.first_name} ${employee.last_name}`,
                                employee_info: {
                                  daily_wage: employee.daily_wage,
                                  commission: employee.commission,
                                  position: employee.position,
                                },
                                month,
                                year,
                                date,
                              };
                              if (
                                (employee.position === "Driller" ||
                                  employee.position === "Locator") &&
                                selectedOptions.length !== 1
                              ) {
                                setExtraModalVisible(true);
                                setExtraPayload({
                                  payload,
                                  value,
                                  selectedOptions,
                                });
                                return;
                              }

                              onChange(value, selectedOptions, payload);
                            }}
                          />
                          <Tooltip
                            title={`${employee.first_name} ${employee.last_name} (${employee.position})`}
                          >
                            <InformationCircleIcon className="w-5 h-5 cursor-pointer" />
                          </Tooltip>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      {/* Payroll */}
      {auth?.userData?.role === "admin" && (
        <section className="mt-6 mx-auto w-1/2">
          <div>
            <Select
              defaultValue={month}
              style={{ width: 120 }}
              onChange={(value) => setMonth(value)}
              options={[
                {
                  value: 0,
                  label: "January",
                },
                {
                  value: 1,
                  label: "February",
                },
                {
                  value: 2,
                  label: "March",
                },
                {
                  value: 3,
                  label: "April",
                },
                {
                  value: 4,
                  label: "May",
                },
                {
                  value: 5,
                  label: "June",
                },
                {
                  value: 6,
                  label: "July",
                },
                {
                  value: 7,
                  label: "August",
                },
                {
                  value: 8,
                  label: "September",
                },
                {
                  value: 9,
                  label: "October",
                },
                {
                  value: 10,
                  label: "November",
                },
                {
                  value: 11,
                  label: "December",
                },
              ]}
            />

            <Select
              defaultValue={year}
              className="ml-2"
              style={{ width: 120 }}
              Select
              onChange={(value) => setYear(value)}
              options={[
                {
                  value: 2022,
                  label: 2022,
                },
                {
                  value: 2023,
                  label: 2023,
                },
                {
                  value: 2024,
                  label: 2024,
                },
                {
                  value: 2025,
                  label: 2025,
                },
                {
                  value: 2026,
                  label: 2022,
                },
              ]}
            />
          </div>

          <div className="my-2 space-x-2">
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: 400 }}
              placeholder="Start date (MM-DD-YYYY) (Must Be In The Same Month)"
              onChange={(date, dateString) => setStartDate(dateString)}
            />
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: 400 }}
              placeholder="Start date (MM-DD-YYYY) (Must Be In The Same Month)"
              onChange={(date, dateString) => setEndDate(dateString)}
            />
          </div>
          <Button onClick={handlePayroll} type="primary">
            Payroll
          </Button>
        </section>
      )}

      <Modal
        open={extraModalVisible}
        title="Additional Information"
        // onOk={handleCreateContractor}
        onCancel={() => setExtraModalVisible((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setExtraModalVisible((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // loading={result.isLoading}
            onClick={() => {
              createAttendance({
                month,
                year,
                employee_id: extraPayload.payload.employee_id,
                employee_name: extraPayload.payload.employee_name,
                employee_info: extraPayload.payload.employee_info,
                total_footage: feetWorked,
                new_records: [
                  {
                    attendance_status:
                      extraPayload.value.length === 1
                        ? extraPayload.value[0]
                        : "Present",
                    project_name:
                      extraPayload.value.length === 1
                        ? ""
                        : extraPayload.selectedOptions[0]?.label,
                    project_id:
                      extraPayload.value.length === 1
                        ? ""
                        : extraPayload.selectedOptions[0]?.value,
                    task_name:
                      extraPayload.value.length === 1
                        ? ""
                        : extraPayload.selectedOptions[1]?.label,
                    task_id:
                      extraPayload.value.length === 1
                        ? ""
                        : extraPayload.selectedOptions[1]?.value,
                    date: extraPayload.payload.date,
                    total_footage: feetWorked,
                  },
                ],
              }).then((res) => {
                if (res.error) {
                  message.error("Something went wrong!");
                  return;
                }
                if (res.data?.success) {
                  message.success("Record added successfully!");
                }
              });
              setExtraModalVisible((prev) => !prev);
            }}
            className="bg-green-500"
          >
            Submit
          </Button>,
        ]}
      >
        <Input
          type="number"
          placeholder="Enter total footage"
          className="my-4"
          value={feetWorked}
          onChange={(e) => setFeetWorked(e.target.value)}
        />
      </Modal>
    </main>
  );
};

export default Attendance;
