import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Button, Input, message, Modal, Popconfirm, Spin, Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAddContractorMutation,
  useDeleteContractorMutation,
  useEditContractorMutation,
  useGetContractorsQuery,
} from "../app/features/api/projectsApiSlice";

const Contractors = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [contractorPhone, setContractorPhone] = useState("");
  const [supervisorPhone, setSupervisorPhone] = useState("");
  const [contractorName, setContractorName] = useState("");
  const [supervisorName, setSupervisorName] = useState("");

  const { data, isLoading, isError } = useGetContractorsQuery();
  const [addContractor, result] = useAddContractorMutation();
  const [deleteContractor] = useDeleteContractorMutation();
  const [editContractor, editResult] = useEditContractorMutation();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editContractorName, setEditContractorName] = useState("");
  const [editSupervisorName, setEditSupervisorName] = useState("");
  const [editContractorPhone, setEditContractorPhone] = useState("");
  const [editSupervisorPhone, setEditSupervisorPhone] = useState("");
  const [editContractorId, setEditContractorId] = useState("");

  const navigate = useNavigate();

  const handleCreateContractor = () => {
    if (!contractorName) {
      message.error("Please enter contractor name");
      return;
    }

    addContractor({
      name: contractorName,
      phone_number: contractorPhone,
      supervisor_number: supervisorPhone,
      supervisor_name: supervisorName,
    }).then((res) => {
      if (res?.data?.success) {
        message.success("Contractor added successfully!");
        setCreateModalOpen((prev) => !prev);
      }
    });
  };

  const handleEditContractor = () => {
    if (!editContractorId) {
      message.error("Something went wrong!");
      return;
    }

    let data = {};

    if (editContractorName) {
      data.name = editContractorName;
    }

    if (editContractorPhone) {
      data.phone_number = editContractorPhone;
    }

    if (editSupervisorPhone) {
      data.supervisor_number = editSupervisorPhone;
    }

    if (editSupervisorName) {
      data.supervisor_name = editSupervisorName;
    }

    editContractor({
      contractor_id: editContractorId,
      ...data,
    }).then((res) => {
      if (res?.data?.success) {
        message.success("Contractor updated successfully!");
        setEditModalOpen((prev) => !prev);
      }
    });
  };

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  return (
    <main className="mt-14 p-2 md:p-4 max-w-[1700px] mx-auto">
      <section>
        <Button
          className="flex items-center gap-x-1 my-3"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="w-5 h-5" />
          <span className="font-bold">Back</span>
        </Button>
      </section>
      <section className="text-center mt-4 md:mt-6">
        <Button
          className="bg-green-500 flex items-center mx-auto gap-x-1 mb-4"
          type="primary"
          size="large"
          onClick={() => setCreateModalOpen((prev) => !prev)}
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add Contractor</span>
        </Button>
      </section>

      <section>
        <Table
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Phone Number",
              dataIndex: "phone_number",
              key: "phone_number",
            },
            {
              title: "Supervisor Name",
              dataIndex: "supervisor_name",
              key: "supervisor_name",
            },
            {
              title: "Supervisor Number",
              dataIndex: "supervisor_number",
              key: "supervisor_number",
            },
            {
              title: "Action",
              key: "action",
              render: (_, item) => (
                <div>
                  <Button
                    onClick={() => {
                      setEditContractorId(item._id);
                      setEditModalOpen((prev) => !prev);
                    }}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    placement="bottomRight"
                    title={"Are you sure to delete this record?"}
                    onConfirm={() => {
                      deleteContractor({
                        contractor_id: item._id,
                      }).then((res) => {
                        if (res?.data?.success) {
                          message.success("Contractor deleted successfully!");
                        }
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger className="ml-2">
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          pagination={false}
          dataSource={data?.contractors || []}
        />
      </section>

      <Modal
        open={createModalOpen}
        title="Add Contractor"
        onOk={handleCreateContractor}
        onCancel={() => setCreateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={result.isLoading}
            onClick={handleCreateContractor}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Input
          className="my-2"
          placeholder="Enter contractor name"
          value={contractorName}
          onChange={(e) => setContractorName(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter contractor phone"
          value={contractorPhone}
          onChange={(e) => setContractorPhone(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter supervisor name"
          value={supervisorName}
          onChange={(e) => setSupervisorName(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter supervisor phone"
          value={supervisorPhone}
          onChange={(e) => setSupervisorPhone(e.target.value)}
        />
      </Modal>

      {/* edit modal */}
      <Modal
        open={editModalOpen}
        title="Edit Contractor"
        onOk={handleEditContractor}
        onCancel={() => setEditModalOpen((prev) => !prev)}
        footer={[
          <Button key="back" onClick={() => setEditModalOpen((prev) => !prev)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={editResult.isLoading}
            onClick={handleEditContractor}
            className="bg-green-500"
          >
            Save
          </Button>,
        ]}
      >
        <Input
          className="my-2"
          placeholder="Enter contractor name"
          value={editContractorName}
          onChange={(e) => setEditContractorName(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter contractor phone"
          value={editContractorPhone}
          onChange={(e) => setEditContractorPhone(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter supervisor name"
          value={editSupervisorName}
          onChange={(e) => setEditSupervisorName(e.target.value)}
        />
        <Input
          className="my-2"
          placeholder="Enter supervisor phone"
          value={editSupervisorPhone}
          onChange={(e) => setEditSupervisorPhone(e.target.value)}
        />
      </Modal>
    </main>
  );
};

export default Contractors;
