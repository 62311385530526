import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { differenceInDays } from "date-fns";
import { useSelector } from "react-redux";
import {
  ChevronLeftIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Button, DatePicker, Input, message, Modal, Spin, Table } from "antd";
import {
  useAddTaskMutation,
  useEditTaskMutation,
  useGetProjectQuery,
} from "../app/features/api/projectsApiSlice";
import ProjectDetailsChart from "../Components/Projects/ProjectDetailsChart";
import { useEditEmployeeFootageMutation } from "../app/features/api/attendanceApiSlice";

const ProjectDetails = () => {
  const { projectId } = useParams();

  const auth = useSelector((state) => state.auth);
  const { data, isLoading, isError, isFetching } =
    useGetProjectQuery(projectId);
  const [editTask, editResult] = useEditTaskMutation();
  const [addTask, addResult] = useAddTaskMutation();
  const [editEmployeeFootage, editEmployeeFootageResult] =
    useEditEmployeeFootageMutation();

  // Local states
  const [id, setId] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [name, setName] = useState(null);
  const [newTaskName, setNewTaskName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [actualStartDate, setActualStartDate] = useState(null);
  const [actualEndDate, setActualEndDate] = useState(null);

  // footage states
  const [isFootageModalOpen, setIsFootageModalOpen] = useState(false);
  const [newFootage, setNewFootage] = useState("");
  const [selectedFootageRecord, setSelectedFootageRecord] = useState(null);

  const navigate = useNavigate();

  if (isLoading || isFetching)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  // Charts Data
  let progressData = {};
  let totalProgress;
  let totalDaysCount;
  let totalTaskDuration;

  if (data) {
    const { tasks } = data;

    totalTaskDuration = tasks.reduce((acc, curr) => {
      if (curr.estimated_end_date || curr.estimated_start_date)
        return (
          acc +
          differenceInDays(
            new Date(curr.estimated_end_date),
            new Date(curr.estimated_start_date)
          )
        );

      return acc + 0;
    }, 0);

    totalDaysCount = tasks.reduce((acc, curr) => {
      if (curr.actual_start_date)
        return (
          acc + differenceInDays(new Date(), new Date(curr.actual_start_date))
        );

      return acc + 0;
    }, 0);

    totalProgress = (totalDaysCount / totalTaskDuration) * 100;

    progressData = {
      labels: ["Estimated Remaining Days", "Total Days Count"],
      datasets: [
        {
          data: [totalTaskDuration - totalDaysCount, totalDaysCount],
          backgroundColor: ["#4ade80", "#eab308"],
          borderColor: ["#4ade80", "#eab308"],
          borderWidth: 1,
        },
      ],
    };
  }

  // Handles
  const handleEditFootage = () => {
    console.log({
      projectId,
      footage: newFootage,
      date: selectedFootageRecord.date,
    });

    editEmployeeFootage({
      projectId,
      footage: newFootage,
      date: selectedFootageRecord.date,
    }).then((res) => {
      if (res?.data?.success) {
        setIsFootageModalOpen(false);
        message.success("Footage updated successfully!");
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  const handleEditTask = (payload, passedId) => {
    editTask({
      task_id: passedId,
      ...payload,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setUpdateModalOpen(false);
        message.success("Task updated successfully!");
      }
    });
  };

  const handleAddTask = () => {
    if (!newTaskName) {
      message.error("Please enter task name!");
      return;
    }

    addTask({
      project_id: projectId,
      name: newTaskName,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setCreateModalOpen((prev) => !prev);
        message.success("Task added successfully!");
      }
    });
  };
  console.log(data);

  const footageColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Footage",
      dataIndex: "footage",
      key: "footage",
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <div>
          <Button
            onClick={() => {
              setSelectedFootageRecord(item);
              setIsFootageModalOpen(true);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <main className="mt-14 p-2 md:p-4">
      <div className="mt-4 flex">
        <Button
          className="flex items-center gap-x-1 ml-4"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="w-5 h-5" />
          <span className="font-bold">Back</span>
        </Button>
        <h2 className="ml-4">
          <span className="text-slate-500">Project: </span>
          <span>{data.project.name}</span>
        </h2>
      </div>
      {auth.userData.role === "admin" && (
        <section className="text-center mt-4 md:mt-6">
          <Button
            className="bg-green-500 flex items-center mx-auto gap-x-1"
            type="primary"
            size="large"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            <PlusIcon className="w-5 h-5" />
            <span>Add Task</span>
          </Button>
        </section>
      )}

      <table className="text-left hidden sm:table mt-5">
        <thead>
          <tr className="font-bold text-lg">
            <th className="text-slate-900">Name</th>
            <th className="text-slate-900">Estimated Start Date</th>
            <th className="text-slate-900">Estimated End Date</th>
            <th className="text-slate-900">Actual Start Date</th>
            <th className="text-slate-900">Actual End Date</th>
            <th className="text-slate-900">Estimated Duration</th>
            <th className="text-slate-900">Days Passed</th>
            <th className="text-slate-900">Days Late</th>
            <th className="text-slate-900">Progress</th>

            {auth.userData?.role === "admin" && (
              <>
                <th className="text-slate-900">Actual Cost</th>
                <th className="text-slate-900">Action</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.tasks.map((task) => (
            <tr key={task._id}>
              <td>
                <span className="text-primary">{task.name}</span>
              </td>
              <td>
                {task.estimated_start_date
                  ? `${new Date(task.estimated_start_date).getMonth() + 1}-${
                      new Date(task.estimated_start_date).getDate() < 10
                        ? `0${new Date(task.estimated_start_date).getDate()}`
                        : new Date(task.estimated_start_date).getDate()
                    }-${new Date(task.estimated_start_date).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {task.estimated_end_date
                  ? `${new Date(task.estimated_end_date).getMonth() + 1}-${
                      new Date(task.estimated_end_date).getDate() < 10
                        ? `0${new Date(task.estimated_end_date).getDate()}`
                        : new Date(task.estimated_end_date).getDate()
                    }-${new Date(task.estimated_end_date).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {task.actual_start_date
                  ? `${new Date(task.actual_start_date).getMonth() + 1}-${
                      new Date(task.actual_start_date).getDate() < 10
                        ? `0${new Date(task.actual_start_date).getDate()}`
                        : new Date(task.actual_start_date).getDate()
                    }-${new Date(task.actual_start_date).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {task.actual_end_date
                  ? `${new Date(task.actual_end_date).getMonth() + 1}-${
                      new Date(task.actual_end_date).getDate() < 10
                        ? `0${new Date(task.actual_end_date).getDate()}`
                        : new Date(task.actual_end_date).getDate()
                    }-${new Date(task.actual_end_date).getFullYear()}`
                  : "N/A"}
              </td>
              <td>
                {task.estimated_start_date && task.estimated_end_date
                  ? `${differenceInDays(
                      new Date(task.estimated_end_date),
                      new Date(task.estimated_start_date)
                    )} days`
                  : "N/A"}
              </td>
              <td>
                {task.actual_start_date
                  ? `${differenceInDays(
                      new Date(),
                      new Date(task.actual_start_date)
                    )} days`
                  : "N/A"}
              </td>
              <td>
                {task.estimated_end_date &&
                differenceInDays(
                  new Date(),
                  new Date(task.estimated_end_date)
                ) > 0
                  ? `${differenceInDays(
                      new Date(),
                      new Date(task.estimated_end_date)
                    )} days`
                  : "N/A"}
              </td>
              <td>
                {task.estimated_start_date &&
                task.estimated_end_date &&
                task.actual_start_date
                  ? ` ${Math.round(
                      (differenceInDays(
                        new Date(),
                        new Date(task.actual_start_date)
                      ) /
                        differenceInDays(
                          new Date(task.estimated_end_date),
                          new Date(task.estimated_start_date)
                        )) *
                        100
                    )} %`
                  : "N/A"}
              </td>

              {auth.userData?.role === "admin" && (
                <>
                  <td>{task.actual_cost}</td>
                  <td>
                    <div className="flex items-center gap-x-2">
                      <Button
                        type="primary"
                        className="bg-green-500"
                        onClick={() => {
                          setId(task._id);
                          setUpdateModalOpen((prev) => !prev);
                        }}
                      >
                        <PencilIcon className="w-5 h-5" />
                      </Button>
                    </div>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Charts */}
      {/* <section className="mt-4">
        <div className="flex justify-around w-full">
          {!isNaN(totalProgress) &&
            totalDaysCount !== 0 &&
            totalTaskDuration !== 0 && (
              <div className="w-[45%] max-w-2xl mt-4">
                <div>
                  <p className="mb-3 text-lg text-slate-600">
                    Total Progress:{" "}
                    <span className="text-primary">
                      {`${totalProgress.toFixed(2)} %` || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <ProjectDetailsChart data={progressData} />
                </div>
              </div>
            )}
        </div>
      </section> */}

      <section className="mt-6">
        <h2 className="text-slate-500 my-4 text-center">Footages</h2>
        <Table
          pagination={false}
          columns={footageColumns}
          dataSource={data?.project?.footages || []}
        />
      </section>

      <Modal
        open={updateModalOpen}
        title="Edit Task"
        onOk={handleEditTask}
        onCancel={() => setUpdateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setUpdateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={editResult.isLoading}
            onClick={() => {
              let task = {};
              if (name) task.name = name;
              if (startDate) task.estimated_start_date = startDate;
              if (endDate) task.estimated_end_date = endDate;
              if (actualStartDate) task.actual_start_date = actualStartDate;
              if (actualEndDate) task.actual_end_date = actualEndDate;

              handleEditTask(
                {
                  ...task,
                },
                id
              );
            }}
            className="bg-green-500"
          >
            Update
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div className="flex mt-3 mb-3 justify-between">
          <DatePicker
            format="MM-DD-YYYY"
            style={{ width: "48%" }}
            placeholder="Estimated start date (MM-DD-YYYY)"
            onChange={(date, dateString) => setStartDate(dateString)}
          />
          <DatePicker
            format="MM-DD-YYYY"
            style={{ width: "48%" }}
            placeholder="Estimated end date (MM-DD-YYYY)"
            onChange={(date, dateString) => setEndDate(dateString)}
          />
        </div>

        <div className="flex mt-3 mb-6 justify-between">
          <DatePicker
            format="MM-DD-YYYY"
            style={{ width: "48%" }}
            placeholder="Actual start date (MM-DD-YYYY)"
            onChange={(date, dateString) => setActualStartDate(dateString)}
          />
          <DatePicker
            format="MM-DD-YYYY"
            style={{ width: "48%" }}
            placeholder="Actual end date (MM-DD-YYYY)"
            onChange={(date, dateString) => setActualEndDate(dateString)}
          />
        </div>
      </Modal>

      {/* New task modal */}
      <Modal
        open={createModalOpen}
        title="Add Task"
        onOk={handleAddTask}
        onCancel={() => setCreateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addResult.isLoading}
            onClick={handleAddTask}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={newTaskName}
          onChange={(e) => setNewTaskName(e.target.value)}
        />
      </Modal>

      {/* footage modal */}
      <Modal
        open={isFootageModalOpen}
        title="Edit Footage"
        onOk={handleEditFootage}
        onCancel={() => setIsFootageModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setIsFootageModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={editEmployeeFootageResult.isLoading}
            onClick={handleEditFootage}
            className="bg-green-500"
          >
            Update
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter new footage"
          className="my-2"
          type="number"
          value={newFootage}
          onChange={(e) => setNewFootage(e.target.value)}
        />
      </Modal>
    </main>
  );
};

export default ProjectDetails;
