import { Badge, Card, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../app/features/api/projectsApiSlice";

const Billing = () => {
  const { data, isLoading, isError } = useGetProjectsQuery();

  // Instances
  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  return (
    <main className="mt-14 p-2 md:p-4">
      <div className="flex gap-8 max-w-[1000px] mx-auto mt-8">
        {data.projects.map((project) => (
          <Badge.Ribbon text={project.status}>
            <Card
              className="w-40 cursor-pointer pt-5"
              onClick={() => navigate(`/billing-details/${project._id}`)}
            >
              <p className="font-bold text-lg">{project.name}</p>
            </Card>
          </Badge.Ribbon>
        ))}
      </div>
    </main>
  );
};

export default Billing;
