import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";

export const employeesApiSlice = createApi({
  reducerPath: "employeesApiSlice",
  tagTypes: ["Employees", "Employee"],
  // eslint-disable-next-line no-undef
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // fetch all employees
    getEmployees: builder.query({
      query: () => "/api/employees",
      providesTags: ["Employees"],
    }),

    // create employee
    createEmployee: builder.mutation({
      query: (body) => ({
        url: "/api/employees/create-employee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Employees"],
    }),

    // edit employee
    editEmployee: builder.mutation({
      query: (body) => ({
        url: "/api/employees/edit-employee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Employees"],
    }),

    // delete employee
    deleteEmployee: builder.mutation({
      query: (body) => ({
        url: "/api/employees/delete-employee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Employees"],
    }),

    // get an employee
    getAnEmployee: builder.query({
      query: (id) => `/api/employees/employee/${id}`,
      providesTags: ["Employee"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetAnEmployeeQuery,
} = employeesApiSlice;
