import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  Input,
  Menu,
  message,
  Modal,
  Tooltip,
  Skeleton,
} from "antd";
import {
  UserIcon,
  TableCellsIcon,
  ListBulletIcon,
  HashtagIcon,
  UsersIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  BellIcon,
  PowerIcon,
  IdentificationIcon,
  CreditCardIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../app/features/authSlice";
import {
  useFetchAuthInfoQuery,
  useUpdateProfileMutation,
} from "../app/features/api/authApiSlice";

const TopBar = () => {
  const auth = useSelector((state) => state.auth);
  const { data, isLoading, isError } = useFetchAuthInfoQuery();
  const [updateProfile, updateResult] = useUpdateProfileMutation();

  // local states
  const [visible, setVisible] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  // instances
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if ((!isLoading && isError) || (!isLoading && !data?.success)) {
      message.warning("Please authenticate again!");
      localStorage.removeItem("PMS__T__111A");
      localStorage.removeItem("PMS__U__111A");
      dispatch(logout());
    }
  }, [isLoading, isError, dispatch, data]);

  // handles
  const handleUpdateUser = () => {
    if (!name && !password) {
      message.error("Please enter name or password!");
      return;
    }

    updateProfile({
      password,
      name,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setUpdateModalOpen((prev) => !prev);
        message.success("Profile updated successfully!");
        if (password) {
          localStorage.removeItem("PMS__T__111A");
          localStorage.removeItem("PMS__U__111A");
          dispatch(logout());
        }
      }
    });
  };

  return (
    <>
      <section className="absolute top-0 w-screen h-14 bg-white flex justify-between items-center px-2 sm:px-4">
        <div className="flex-grow">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[
              `${
                location.pathname.includes("projects")
                  ? "/projects"
                  : location.pathname
              }`,
            ]}
            className="border-0"
          >
            {auth.userData?.role === "admin" && (
              <Menu.Item key="/" onClick={() => navigate("/")}>
                <div className="flex items-center justify-center">
                  <HashtagIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                  <span className="hidden md:inline-block ml-1">Dashboard</span>
                </div>
              </Menu.Item>
            )}

            <Menu.Item
              key="/attendance"
              onClick={() => navigate("/attendance")}
            >
              <div className="flex items-center justify-center">
                <TableCellsIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                <span className="hidden md:inline-block ml-1">Attendance</span>
              </div>
            </Menu.Item>

            <Menu.Item key="/projects" onClick={() => navigate("/projects")}>
              <div className="flex items-center justify-center">
                <ListBulletIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                <span className="hidden md:inline-block ml-1">Projects</span>
              </div>
            </Menu.Item>

            <Menu.Item key="/employees" onClick={() => navigate("/employees")}>
              <div className="flex items-center justify-center">
                <IdentificationIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                <span className="hidden md:inline-block ml-1">Employees</span>
              </div>
            </Menu.Item>

            {auth.userData?.role === "admin" && (
              <Menu.Item key="/billing" onClick={() => navigate("/billing")}>
                <div className="flex items-center justify-center">
                  <CreditCardIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                  <span className="hidden md:inline-block ml-1">Billing</span>
                </div>
              </Menu.Item>
            )}

            <Menu.Item key="/assets" onClick={() => navigate("/assets")}>
              <div className="flex items-center justify-center">
                <TruckIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                <span className="hidden md:inline-block ml-1">Assets</span>
              </div>
            </Menu.Item>

            {auth.userData.role === "admin" && (
              <Menu.Item key="/users" onClick={() => navigate("/users")}>
                <div className="flex items-center justify-center">
                  <UsersIcon className="md:w-6 md:h-6 mb-1 md:m-0 w-7 h-7" />
                  <span className="hidden md:inline-block ml-1">Users</span>
                </div>
              </Menu.Item>
            )}
          </Menu>
        </div>
        {/* <div className="space-x-2 hidden sm:inline-block flex-grow">
          <span className="font-bold">{format(new Date(), "p")}</span>
          <span>{format(new Date(), "PPPP")}</span>
        </div> */}
        <div className="relative flex items-center gap-x-2">
          {auth.userData.name && !isLoading && (
            <div className="hidden md:inline-block">
              Welcome,
              <span className="font-bold"> {`${data?.user?.name}`}</span>
            </div>
          )}
          {isLoading && (
            <div className="hidden md:inline-block">
              <Skeleton.Button active />
            </div>
          )}
          {auth.userData.name !== "Guest" ? (
            <Avatar
              size="large"
              className="bg-primary flex items-center justify-center cursor-pointer text-xl"
              onClick={() => setVisible((prev) => !prev)}
            >
              {auth.userData.name.split("")[0]}
            </Avatar>
          ) : (
            <Avatar
              size="large"
              className="bg-primary flex items-center justify-center cursor-pointer"
              icon={<UserIcon className="w-6 h-6" />}
              onClick={() => setVisible((prev) => !prev)}
            />
          )}

          {/* profile popup */}
          {visible && (
            <div className="absolute top-10 bg-white -right-2 md:-right-4 px-3 py-4 md:px-4">
              <Tooltip title="Settings" placement="left">
                <span
                  className="flex justify-between items-center p-2 my-2 cursor-pointer hover:bg-primary transition-all duration-150 ease-out hover:text-white rounded-full"
                  onClick={() => setUpdateModalOpen((prev) => !prev)}
                >
                  <Cog6ToothIcon className="w-5 h-5 md:w-6 md:h-6" />
                </span>
              </Tooltip>

              <Tooltip title="Notifications" placement="left">
                <span className="flex justify-between items-center p-2 my-2 cursor-pointer hover:bg-primary transition-all duration-150 ease-out hover:text-white rounded-full">
                  <BellIcon className="w-5 h-5 md:w-6 md:h-6" />
                </span>
              </Tooltip>

              <Tooltip title="About" placement="left">
                <span className="flex justify-between items-center p-2 my-2 cursor-pointer hover:bg-primary transition-all duration-150 ease-out hover:text-white rounded-full">
                  <InformationCircleIcon className="w-5 h-5 md:w-6 md:h-6" />
                </span>
              </Tooltip>

              <Tooltip title="Logout" placement="left">
                <span
                  className="flex justify-center items-center p-2 my-2 cursor-pointer hover:bg-primary transition-all duration-150 ease-out hover:text-white rounded-full"
                  onClick={() => {
                    localStorage.removeItem("PMS__T__111A");
                    localStorage.removeItem("PMS__U__111A");
                    dispatch(logout());
                  }}
                >
                  <PowerIcon className="w-5 h-5 md:w-6 md:h-6" />
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </section>

      <Modal
        open={updateModalOpen}
        title="Update Profile"
        onOk={handleUpdateUser}
        onCancel={() => setUpdateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setUpdateModalOpen((prev) => !prev)}
          >
            return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updateResult.isLoading}
            onClick={handleUpdateUser}
            className="bg-green-500"
          >
            Save
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Enter password"
          className="mt-3 mb-4"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default TopBar;
