import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker, Input, message, Modal, Select, Spin } from "antd";
import { format, getWeek } from "date-fns";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  useGetProjectsQuery,
  useEditProjectMutation,
} from "../../app/features/api/projectsApiSlice";

const ProjectsList = () => {
  const auth = useSelector((state) => state.auth);
  const { data, isLoading, isError, isFetching } = useGetProjectsQuery();
  const [editProject, editResult] = useEditProjectMutation();

  // Local states
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [id, setId] = useState(null);

  const [franchiseName, setFranchiseName] = useState(null);
  const [region, setRegion] = useState(null);
  const [totalCmh, setTotalCmh] = useState(null);
  const [estimatedStartDate, setEstimatedStartDate] = useState(null);
  const [wr, setWr] = useState(null);

  // Instances
  const navigate = useNavigate();

  if (isLoading || isFetching)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  // Handles
  const handleEditProject = (payload, passedId) => {
    if (!passedId) {
      message.error("Please try again!");
      return;
    }

    editProject({ project_id: passedId, ...payload }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setUpdateModalOpen(false);
        message.success("Project updated successfully!");
      }
    });
  };

  return (
    <section className="mt-4 md:mt-6">
      <table className="text-left hidden sm:table">
        <thead>
          <tr className="font-bold text-lg">
            <th className="text-slate-900">Status</th>
            <th className="text-slate-900">Name</th>
            <th className="text-slate-900">Region</th>
            <th className="text-slate-900">Tasks</th>
            <th className="text-slate-900">Total CMH</th>
            <th className="text-slate-900">Total Footage</th>
            <th className="text-slate-900">Footage Today</th>
            <th className="text-slate-900">Footage This Week</th>
            <th className="text-slate-900">Estimated Start Date</th>
            <th className="text-slate-900">Start Date</th>
            <th className="text-slate-900">Estimated End Date</th>
            <th className="text-slate-900">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.projects.map((project) => (
            <tr key={project._id}>
              <td>
                <Select
                  style={{ width: 150 }}
                  className="mt-2 mb-4"
                  defaultValue={project.status}
                  onChange={(value) => {
                    setId(project._id);
                    handleEditProject({ status: value }, project._id);
                  }} // possible values: "In Progress", "Completed", "Cancelled"
                  options={[
                    {
                      value: "Not Started",
                      label: "Not Started",
                    },
                    {
                      value: "In Progress",
                      label: "In Progress",
                    },
                    {
                      value: "On Hold",
                      label: "On Hold",
                    },
                    {
                      value: "Completed",
                      label: "Completed",
                    },
                    {
                      value: "Cancelled",
                      label: "Cancelled",
                    },
                  ]}
                />
              </td>
              <td
                className="cursor-pointer"
                onClick={() => navigate(`/project-details/${project._id}`)}
              >
                <span className="text-primary">{project.name}</span>
              </td>
              <td>{project.region || "N/A"}</td>
              <td>{project.tasks.length}</td>
              <td>{project.total_cmh || "N/A"}</td>
              <td>
                {project.footages?.reduce(
                  (acc, curr) => acc + Number(curr.footage),
                  0
                )}
              </td>
              <td>
                {
                  // filter all the records that are today
                  project.footages
                    ?.filter((footage) => {
                      const today = new Date();
                      const footageDate = new Date(footage.date);
                      return (
                        today.toDateString() === footageDate.toDateString()
                      );
                    })
                    .reduce((acc, curr) => acc + Number(curr.footage), 0)
                }
              </td>
              <td>
                {
                  // filter all the records that are this week
                  project.footages
                    ?.filter((footage) => {
                      const today = new Date();
                      const footageDate = new Date(footage.date);
                      return (
                        getWeek(today) === getWeek(footageDate) &&
                        today.getFullYear() === footageDate.getFullYear()
                      );
                    })
                    .reduce((acc, curr) => acc + Number(curr.footage), 0)
                }
              </td>
              <td>
                {project.estimated_start_date
                  ? format(new Date(project.estimated_start_date), "MM-dd-yyyy")
                  : "N/A"}
              </td>
              <td>
                {project.start_date
                  ? format(new Date(project.start_date), "MM-dd-yyyy")
                  : "N/A"}
              </td>
              <td>
                {project.end_date
                  ? format(new Date(project.end_date), "MM-dd-yyyy")
                  : "N/A"}
              </td>
              <td>
                <div className="flex items-center gap-x-2">
                  <Button
                    type="primary"
                    className="bg-green-500"
                    onClick={() => {
                      setId(project._id);
                      setUpdateModalOpen((prev) => !prev);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={updateModalOpen}
        title="Edit Project"
        onOk={handleEditProject}
        onCancel={() => setUpdateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setUpdateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={editResult.isLoading}
            onClick={() => {
              let fullName;
              if (wr && name) {
                fullName = `${name} - ${wr}`;
              }
              handleEditProject(
                {
                  name: fullName || name,
                  region,
                  franchise_name: franchiseName,
                  start_date: startDate,
                  end_date: endDate,
                  estimated_start_date: estimatedStartDate,
                  total_cmh: totalCmh,
                },
                id
              );
            }}
            className="bg-green-500"
          >
            Edit
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Enter WR number"
          className="my-2"
          value={wr}
          onChange={(e) => setWr(e.target.value)}
        />

        <Select
          placeholder="Select region"
          style={{
            width: 200,
          }}
          options={[
            {
              value: "North",
              label: "North",
            },
            {
              value: "East",
              label: "East",
            },
            {
              value: "Broward",
              label: "Broward",
            },
          ]}
          className="my-2"
          onChange={(value) => setRegion(value)}
        />
        <Input
          placeholder="Enter total CMH"
          className="my-2"
          type="number"
          value={totalCmh}
          onChange={(e) => setTotalCmh(e.target.value)}
        />
        <Input
          placeholder="Enter franchise name"
          className="my-2"
          value={franchiseName}
          onChange={(e) => setFranchiseName(e.target.value)}
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Estimated Start Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setEstimatedStartDate(dateStr)}
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Start Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setStartDate(dateStr)}
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="End Date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateStr) => setEndDate(dateStr)}
        />
      </Modal>
    </section>
  );
};

export default ProjectsList;
