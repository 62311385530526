import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  DatePicker,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Table,
} from "antd";
import { useState } from "react";
import { format } from "date-fns";
import {
  useCreateMantainanceMutation,
  useDeleteMantainanceMutation,
  useGetAssetsQuery,
  useGetMantainancesQuery,
} from "../app/features/api/assetsApiSlice";
import { useNavigate } from "react-router-dom";

const Mantainance = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { data: assetsData } = useGetAssetsQuery();
  const { data, isLoading, isError } = useGetMantainancesQuery();
  const [createMantainance, result] = useCreateMantainanceMutation();
  const [deleteMantainance] = useDeleteMantainanceMutation();

  const [mantainanceDate, setMantainanceDate] = useState("");
  const [assetId, setAssetId] = useState("");
  const [oilChanged, setOilChanged] = useState(false);
  const [tiresRotated, setTiresRotated] = useState(false);
  const [tireChanged, setTireChanged] = useState(false);

  const navigate = useNavigate();

  const handleCreateRecord = () => {
    if (!mantainanceDate || !assetId) {
      message.error("Asset name and mantainance date are required!");
      return;
    }

    createMantainance({
      asset_id: assetId,
      date_of_mantainance: mantainanceDate,
      oil_change: oilChanged,
      tire_rotation: tiresRotated,
      tire_change: tireChanged,
    }).then((res) => {
      if (res?.data?.success) {
        message.success("Mantainance record added successfully!");
        setCreateModalOpen(false);
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "asset_name",
      key: "asset_name",
    },
    {
      title: "Vehicle Year",
      dataIndex: "year",
      key: "year",
      render: (_, record) => <p>{record?.year || "N/A"}</p>,
    },
    {
      title: "Date of Mantainance",
      dataIndex: "date_of_mantainance",
      key: "date_of_mantainance",
      render: (_, record) => (
        <p>{format(new Date(record.date_of_mantainance), "MM-dd-yyyy")}</p>
      ),
    },
    {
      title: "Oil Change Status",
      dataIndex: "oil_change",
      key: "oil_change",
      render: (_, record) => (
        <Checkbox checked={record.oil_change}>Oil Changed</Checkbox>
      ),
    },
    {
      title: "Tire Change Status",
      dataIndex: "tire_change",
      key: "tire_change",
      render: (_, record) => (
        <Checkbox checked={record.tire_change}>Tire Changed</Checkbox>
      ),
    },
    {
      title: "Tire Rotation Status",
      dataIndex: "tire_rotation",
      key: "tire_rotation",
      render: (_, record) => (
        <Checkbox checked={record.tire_rotation}>Tires Rotated</Checkbox>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <Popconfirm
          placement="bottomRight"
          title={"Are you sure to delete this record?"}
          onConfirm={() => {
            deleteMantainance({ id: item._id }).then((res) => {
              if (res?.data?.success) {
                message.success("Record deleted successfully!");
              } else {
                message.error("Something went wrong!");
              }
            });
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button danger className="ml-2">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <main className="mt-14 p-2 md:p-4 max-w-[1700px] mx-auto">
      <section>
        <Button
          className="flex items-center gap-x-1 ml-4"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="w-5 h-5" />
          <span className="font-bold">Back</span>
        </Button>
      </section>
      <section className="text-center mt-4 md:mt-6">
        <Button
          className="bg-green-500 flex items-center mx-auto gap-x-1"
          type="primary"
          size="large"
          onClick={() => setCreateModalOpen((prev) => !prev)}
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add Mantainance Record</span>
        </Button>
      </section>

      <section>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data.mantainances || []}
          className="mt-4 md:mt-8"
          pagination={false}
          key={(item) => item._id}
        />
      </section>

      <Modal
        open={createModalOpen}
        title="Add Mantainance Record"
        onOk={handleCreateRecord}
        onCancel={() => setCreateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setCreateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={result.isLoading}
            onClick={handleCreateRecord}
            className="bg-green-500"
          >
            Add
          </Button>,
        ]}
      >
        <Select
          style={{ width: 320 }}
          className="my-2"
          options={assetsData?.assets
            ?.filter((item) => item?.type?.includes("Vehicle"))
            .map((asset) => {
              return {
                value: asset._id,
                label: `${asset?.vehicle_info?.name} - ${asset?.tag}`,
              };
            })}
          onChange={(value) => setAssetId(value)}
          placeholder="Select asset"
        />
        <DatePicker
          format="MM-DD-YYYY"
          style={{ width: "100%" }}
          placeholder="Enter mantainance date (MM-DD-YYYY)"
          className="my-2"
          onChange={(date, dateString) => setMantainanceDate(dateString)}
        />
        <Checkbox
          checked={oilChanged}
          onChange={(e) => setOilChanged(e.target.checked)}
          className="my-2"
        >
          Oil Changed
        </Checkbox>
        <Checkbox
          checked={tiresRotated}
          onChange={(e) => setTiresRotated(e.target.checked)}
          className="my-2"
        >
          Tires Rotated
        </Checkbox>
        <Checkbox
          checked={tireChanged}
          onChange={(e) => setTireChanged(e.target.checked)}
          className="my-2"
        >
          Tires Changed
        </Checkbox>
      </Modal>
    </main>
  );
};

export default Mantainance;
