import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";

export const attendanceApiSlice = createApi({
  reducerPath: "attendanceApiSlice",
  tagTypes: ["Attendance"],
  // eslint-disable-next-line no-undef
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // fetch all attendances
    getAttendances: builder.query({
      query: ({ month, year }) =>
        `/api/attendances?month=${month}&year=${year}`,
      providesTags: ["Attendance"],
    }),
    // fetch attendance
    getAttendance: builder.query({
      query: (attendanceId) => `/api/attendances/${attendanceId}`,
      providesTags: ["Attendance"],
    }),
    // create attendance
    createAttendance: builder.mutation({
      query: (body) => ({
        url: "/api/attendances/create-attendance",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Attendance"],
    }),

    // update footage
    editEmployeeFootage: builder.mutation({
      query: (body) => ({
        url: "/api/attendances/update-footage",
        method: "POST",
        body,
      }),
      // invalidate Project tag from projectApiSlice
      invalidatesTags: ["Project"],
    }),
  }),
});

export const {
  useGetAttendancesQuery,
  useCreateAttendanceMutation,
  useGetAttendanceQuery,
  useEditEmployeeFootageMutation,
} = attendanceApiSlice;
