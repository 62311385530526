import { Button, Input, message, Modal, Popconfirm, Spin } from "antd";
import { format } from "date-fns";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  useDeleteUserMutation,
  useFetchAllUsersQuery,
  useUpdateUserMutation,
} from "../../app/features/api/authApiSlice";
import { useState } from "react";

const ManagerList = () => {
  const { data, isLoading, isError } = useFetchAllUsersQuery();
  const [deleteUser, deleteResult] = useDeleteUserMutation();
  const [updateUser, updateResult] = useUpdateUserMutation();

  // Local states
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  if (isLoading)
    return (
      <div className="h-full min-h-[300px] w-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    message.error("Something went wrong!");
    return null;
  }

  // Handles
  const handleDeleteUser = (id) => {
    deleteUser({ id }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("User deleted successfully!");
      }
    });
  };

  const handleUpdateUser = () => {
    if (!name && !password) {
      message.error("Please enter name or password!");
      return;
    }

    updateUser({
      username,
      password,
      name: name,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        setUpdateModalOpen((prev) => !prev);
        message.success("User updated successfully!");
      }
    });
  };

  return (
    <section className="mt-4 md:mt-6">
      <table className="text-left hidden sm:table">
        <thead>
          <tr className="font-bold text-lg">
            <th className="text-slate-900">Username</th>
            <th className="text-slate-900">Name</th>
            <th className="text-slate-900">Role</th>
            <th className="text-slate-900">Created At</th>
            <th className="text-slate-900">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.users.map((manager) => (
            <tr key={manager._id}>
              <td className="text-primary">{manager.username}</td>
              <td>{manager.name}</td>
              <td className="capitalize">{manager.role}</td>
              <td className="hiden">
                {format(new Date(manager.createdAt), "MM-dd-yyyy")}
              </td>
              <td>
                <div className="flex items-center gap-x-2">
                  <Button
                    type="primary"
                    className="bg-green-500"
                    onClick={() => {
                      setUsername(manager.username);
                      setUpdateModalOpen((prev) => !prev);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                  </Button>
                  <Popconfirm
                    title={`Are you sure to terminate ${manager.username}?`}
                    onConfirm={() => handleDeleteUser(manager._id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={
                      ({ danger: true }, { loading: deleteResult.isLoading })
                    }
                  >
                    <Button danger>
                      <TrashIcon className="w-5 h-5" />
                    </Button>
                  </Popconfirm>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* responsive table */}
      <table className="text-left sm:hidden">
        <thead>
          <tr className="font-bold text-lg">
            <th className="text-slate-900">Username</th>
            <th className="text-slate-900">Name</th>
            <th className="text-slate-900">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.users.map((manager) => (
            <tr key={manager._id}>
              <td className="text-primary">{manager.username}</td>
              <td>{manager.name}</td>
              <td>
                <div className="flex items-center gap-x-2">
                  <Button
                    type="primary"
                    className="bg-green-500"
                    onClick={() => {
                      setUsername(manager.username);
                      setUpdateModalOpen((prev) => !prev);
                    }}
                  >
                    <PencilIcon className="w-5 h-5" />
                  </Button>
                  <Popconfirm
                    title={`Are you sure to terminate ${manager.username}?`}
                    onConfirm={() => handleDeleteUser(manager._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger loading={deleteResult.isLoading}>
                      <TrashIcon className="w-5 h-5" />
                    </Button>
                  </Popconfirm>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={updateModalOpen}
        title="Edit Manager"
        onOk={handleUpdateUser}
        onCancel={() => setUpdateModalOpen((prev) => !prev)}
        footer={[
          <Button
            key="back"
            onClick={() => setUpdateModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updateResult.isLoading}
            onClick={handleUpdateUser}
            className="bg-green-500"
          >
            Update
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter name"
          className="my-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Enter password"
          className="mt-3 mb-4"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </section>
  );
};

export default ManagerList;
