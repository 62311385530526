import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { persistToken, persistUserData } from "./app/features/authSlice";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import Managers from "./Pages/Managers";
import Attendance from "./Pages/Attendance";
import Projects from "./Pages/Projects";
import Employees from "./Pages/Employees";
import ProjectDetails from "./Pages/ProjectDetails";
import AttendanceDetails from "./Pages/AttendanceDetails";
import CreateEmployee from "./Pages/CreateEmployee";
import Billing from "./Pages/Billing";
import Assets from "./Pages/Assets";
import BillingDetails from "./Pages/BillingDetails";
import CreateAsset from "./Pages/CreateAsset";
import AssetDetails from "./Pages/AssetDetails";
import EditEmployee from "./Pages/EditEmployee";
import EditAsset from "./Pages/EditAsset";
import Mantainance from "./Pages/Mantainance";
import Contractors from "./Pages/Contractors";

const App = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  // instances
  const dispatch = useDispatch();

  // exctracting token from local storage
  useEffect(() => {
    const token = localStorage.getItem("PMS__T__111A");
    const userData = localStorage.getItem("PMS__U__111A");

    if (token) dispatch(persistToken(token));
    if (userData) dispatch(persistUserData(JSON.parse(userData)));

    setLoading(false);
  }, [dispatch]);

  if (loading)
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            element={<ProtectedRoutes authenticated={auth.isAuthenticated} />}
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Managers />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/project-details/:projectId"
              element={<ProjectDetails />}
            />
            <Route
              path="/attendance-details/:attendanceId"
              element={<AttendanceDetails />}
            />
            <Route path="/employees" element={<Employees />} />
            <Route path="/billing" element={<Billing />} />
            <Route
              path="/billing-details/:projectId"
              element={<BillingDetails />}
            />
            <Route path="/assets" element={<Assets />} />
            <Route path="/asset-details/:assetId" element={<AssetDetails />} />
            <Route path="/edit-asset/:assetId" element={<EditAsset />} />
            <Route path="/create-asset" element={<CreateAsset />} />
            <Route path="/create-employee" element={<CreateEmployee />} />
            <Route
              path="/edit-employee/:employeeId"
              element={<EditEmployee />}
            />

            <Route path="/mantainance" element={<Mantainance />} />
            <Route path="/contractors" element={<Contractors />} />
            <Route path="*" element={<div>404</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
