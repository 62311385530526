import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssetsList from "../Components/Assets/AssetsList";

const Assets = () => {
  const auth = useSelector((state) => state.auth);

  // Instances
  const navigate = useNavigate();

  return (
    <main className="mt-14 p-2 md:p-4">
      {auth.userData.role === "admin" && (
        <section className="flex mt-4 md:mt-6 justify-center gap-x-4">
          <Button
            className="bg-green-500 flex items-center gap-x-1"
            type="primary"
            size="large"
            onClick={() => navigate("/create-asset")}
          >
            <PlusIcon className="w-5 h-5" />
            <span>Add Asset</span>
          </Button>
          <Button size="large" onClick={() => navigate("/mantainance")}>
            <span>Mantainance Records</span>
          </Button>
        </section>
      )}

      <AssetsList />
    </main>
  );
};

export default Assets;
