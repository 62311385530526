import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";

export const billingApiSlice = createApi({
  reducerPath: "billingApiSlice",
  tagTypes: ["Invoices", "ContractorCosts"],
  // eslint-disable-next-line no-undef
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // get all invoices
    getInvoices: builder.query({
      query: (projectId) => `/api/billings/invoices/${projectId}`,
      providesTags: ["Invoices"],
    }),

    // get all contractor costs
    getContractorCosts: builder.query({
      query: (projectId) => `/api/billings/contractor-costs/${projectId}`,
      providesTags: ["ContractorCosts"],
    }),

    // add invoice
    addInvoice: builder.mutation({
      query: (body) => ({
        url: "/api/billings/add-invoice",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invoices"],
    }),

    // add contractor cost
    addContractorCost: builder.mutation({
      query: (body) => ({
        url: "/api/billings/add-contractor-cost",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ContractorCosts"],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetContractorCostsQuery,
  useAddInvoiceMutation,
  useAddContractorCostMutation,
} = billingApiSlice;
