import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";

export const assetsApiSlice = createApi({
  reducerPath: "assetsApiSlice",
  tagTypes: ["Assets", "SubTypes", "Mantainances"],
  // eslint-disable-next-line no-undef
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // fetch all assets
    getAssets: builder.query({
      query: () => `/api/assets`,
      providesTags: ["Assets"],
    }),

    // create asset
    createAsset: builder.mutation({
      query: (body) => ({
        url: "/api/assets/create-asset",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Assets"],
    }),

    // edit asset
    editAsset: builder.mutation({
      query: (body) => ({
        url: "/api/assets/edit-asset",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Assets"],
    }),

    // delete asset
    deleteAsset: builder.mutation({
      query: (body) => ({
        url: "/api/assets/delete-asset",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Assets"],
    }),

    // get asset by id
    getAssetById: builder.query({
      query: (id) => `/api/assets/asset/${id}`,
    }),

    // get sub types
    getSubTypes: builder.query({
      query: () => `/api/assets/sub-types`,
      providesTags: ["SubTypes"],
    }),

    // add sub type
    addSubType: builder.mutation({
      query: (body) => ({
        url: "/api/assets/add-sub-type",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SubTypes"],
    }),

    // create mantainance
    createMantainance: builder.mutation({
      query: (body) => ({
        url: "/api/mantainances/create-mantainance",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mantainances"],
    }),

    // fetch all mantainances
    getMantainances: builder.query({
      query: () => `/api/mantainances`,
      providesTags: ["Mantainances"],
    }),

    // delete mantainance
    deleteMantainance: builder.mutation({
      query: (body) => ({
        url: "/api/mantainances/delete-mantainance",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mantainances"],
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useCreateAssetMutation,
  useEditAssetMutation,
  useDeleteAssetMutation,
  useGetAssetByIdQuery,
  useGetSubTypesQuery,
  useAddSubTypeMutation,
  useCreateMantainanceMutation,
  useGetMantainancesQuery,
  useDeleteMantainanceMutation,
} = assetsApiSlice;
