import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useGetEmployeesQuery } from "../app/features/api/employeesApiSlice";
import {
  useAddSubTypeMutation,
  useCreateAssetMutation,
  useGetSubTypesQuery,
} from "../app/features/api/assetsApiSlice";

const CreateAsset = () => {
  const { data } = useGetEmployeesQuery();
  const { data: subTypesData } = useGetSubTypesQuery();
  const [createAsset, result] = useCreateAssetMutation();
  const [addSubType, subTypeResult] = useAddSubTypeMutation();

  const [form] = Form.useForm();
  const [type, setType] = useState("Vehicle");

  const [subType, setSubType] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("");

  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const onFinish = (values) => {
    let data;
    if (type === "Vehicle") {
      let {
        tag_expiration,
        last_maintenance,
        next_maintenance,
        tag,
        assigned_to,
        type,
        ...rest
      } = values;

      if (selectedSubType) {
        type = `${type} > ${selectedSubType}`;
      }

      tag_expiration = tag_expiration?.format("MM-DD-YYYY") || undefined;
      last_maintenance = last_maintenance?.format("MM-DD-YYYY") || undefined;
      next_maintenance = next_maintenance?.format("MM-DD-YYYY") || undefined;

      data = {
        tag,
        type,
        tag_expiration,
        assigned_to,
        vehicle_info: {
          ...rest,
          tag_expiration,
          last_maintenance,
          next_maintenance,
        },
      };
    }

    if (type === "Property") {
      let { tag_expiration, tag, assigned_to, type, ...rest } = values;

      if (selectedSubType) {
        type = `${type} > ${selectedSubType}`;
      }

      tag_expiration = tag_expiration?.format("MM-DD-YYYY") || undefined;

      data = {
        tag,
        type,
        tag_expiration,
        assigned_to,
        warehouse_info: {
          ...rest,
        },
      };
    }

    if (type === "Equipment") {
      let { tag_expiration, tag, assigned_to, type } = values;

      if (selectedSubType) {
        type = `${type} > ${selectedSubType}`;
      }

      tag_expiration = tag_expiration?.format("MM-DD-YYYY") || undefined;

      data = {
        tag,
        type,
        tag_expiration,
        assigned_to,
      };
    }

    createAsset(data).then((res) => {
      if (res.data.success) {
        message.success("Asset created successfully!");
        navigate("/assets");
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4 ">
      <Button
        className="flex items-center gap-x-1 ml-4"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>

      <div className="max-w-5xl mt-4 ">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: "Please select asset type!",
              },
            ]}
          >
            <Select
              options={[
                { label: "Vehicle", value: "Vehicle" },
                { label: "Property", value: "Property" },
                { label: "Equipment", value: "Equipment" },
              ]}
              onChange={(value) => setType(value)}
            />
          </Form.Item>
          <div className="flex justify-end my-3 gap-x-2">
            <Select
              placeholder="Select sub type"
              style={{
                width: 150,
              }}
              options={subTypesData?.subTypes?.map((subType) => {
                return {
                  label: subType.name,
                  value: subType.name,
                };
              })}
              onChange={(value) => setSelectedSubType(value)}
            />
            <div className="flex gap-x-2">
              <Input
                className="w-44"
                placeholder="Enter new sub type"
                value={subType}
                onChange={(e) => setSubType(e.target.value)}
              />
              <Button
                onClick={() => {
                  addSubType({ name: subType }).then((res) => {
                    if (res.data.success) {
                      message.success("Sub type added successfully!");
                    } else {
                      message.error("Something went wrong!");
                    }
                  });
                  setSubType("");
                }}
                loading={subTypeResult.isLoading}
              >
                Add
              </Button>
            </div>
          </div>
          <Form.Item
            name="tag"
            label="Tag"
            rules={[
              {
                required: true,
                message: "Tag is required to indentify every asset!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tag_expiration"
            label="Tag Expiration Date"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Tag expiration date (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="assigned_to"
            label="Assigned To"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Select
              options={
                data
                  ? data?.employees
                      ?.map((employee) => {
                        return {
                          label: employee.first_name,
                          value: employee.first_name,
                        };
                      })
                      .concat({
                        label: "Overhead",
                        value: "Overhead",
                      })
                  : []
              }
            />
          </Form.Item>

          <div>
            {type === "Vehicle" && (
              <>
                <Form.Item
                  name="year"
                  label="Vehicle Year"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="mileage"
                  label="Mileage"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="daily_cost"
                  label="Daily Cost"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="loan_amount"
                  label="Loan Amount"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_loan_payment"
                  label="Monthly Loan Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_insurance_payment"
                  label="Monthly Insurance Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="last_maintenance"
                  label="Last Maintenance"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Last maintenance date (MM-DD-YYYY)"
                  />
                </Form.Item>

                <Form.Item
                  name="next_maintenance"
                  label="Next Maintenance"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Next maintenance date (MM-DD-YYYY)"
                  />
                </Form.Item>
              </>
            )}
          </div>

          <div>
            {type === "Property" && (
              <>
                <Form.Item
                  name="location"
                  label="Location"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="zip"
                  label="Zip"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="monthly_rent"
                  label="Monthly Rent/Mortgage"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_insurance_payment"
                  label="Monthly Insurance Payment"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="monthly_utility_cost"
                  label="Monthly Utility Cost"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </>
            )}
          </div>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-green-500"
              loading={result.isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default CreateAsset;
