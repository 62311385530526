import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useCreateEmployeeMutation } from "../app/features/api/employeesApiSlice";
import { useState } from "react";

const CreateEmployee = () => {
  const [form] = Form.useForm();
  const [createEmployee, result] = useCreateEmployeeMutation();
  const [isOverHead, setIsOverHead] = useState(false);

  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  // Possible values: "Driller", "Laborer", "Mini Operator", "Supervisor", "Cable Crew", "Locator"
  const positionList = [
    {
      value: "Driller",
      label: "Driller",
    },
    {
      value: "Laborer",
      label: "Laborer",
    },
    {
      value: "Mini Operator",
      label: "Mini Operator",
    },
    {
      value: "Supervisor",
      label: "Supervisor",
    },
    {
      value: "Cable Crew",
      label: "Cable Crew",
    },
    {
      value: "Locator",
      label: "Locator",
    },
  ];

  const onFinish = (values) => {
    let {
      date_of_birth,
      join_date,
      termination_date,
      street,
      state,
      city,
      zip,
      overhead,
      ...rest
    } = values;
    let home_address = {
      street,
      state,
      city,
      zip,
    };
    date_of_birth = date_of_birth?.format("MM-DD-YYYY") || undefined;
    join_date = join_date?.format("MM-DD-YYYY") || undefined;
    termination_date = termination_date?.format("MM-DD-YYYY") || undefined;
    overhead = isOverHead;

    // check if the birth date is not in the future
    if (date_of_birth) {
      const today = new Date();
      const birthDate = new Date(date_of_birth);
      if (birthDate > today) {
        message.error("Date of birth cannot be in the future!");
        return;
      }
    }

    createEmployee({
      date_of_birth,
      join_date,
      termination_date,
      home_address,
      overhead,
      ...rest,
    }).then((res) => {
      if (res.error) {
        message.error("Something went wrong!");
        return;
      }

      if (res.data?.success) {
        message.success("Employee added successfully!");
        navigate("/employees");
      }
    });
  };

  return (
    <main className="mt-14 p-2 md:p-4 ">
      <Button
        className="flex items-center gap-x-1 ml-4"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="font-bold">Back</span>
      </Button>
      <div className="max-w-5xl mt-4 lg:mt-8">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input employee firstname!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="middle_name"
            label="Middle Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="date_of_birth"
            label="Date of Birth"
            rules={[{ required: true, message: "Date of birth is required!" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Date of birth (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            hasFeedback
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      new Error("Please input employee phone number!")
                    );
                  }

                  if (value.length !== 10) {
                    return Promise.reject(
                      new Error("Phone number must be 10 digits!")
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input addonBefore="+1" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="street"
            label="Home Address"
            rules={[
              {
                required: true,
                message: "Please input employee address!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <div className="flex justify-end">
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please input employee address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please input employee address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zip"
              label="Zip Code"
              rules={[
                {
                  required: true,
                  message: "Please input employee address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item
            name="position"
            label="Position"
            rules={[
              { required: true, message: "Please input employee position" },
            ]}
          >
            <Select
              options={positionList}
              placeholder="Select employee position"
            />
          </Form.Item>

          <Form.Item
            name="daily_wage"
            label="Daily Wage"
            rules={[
              { required: true, message: "Please input employee daily wage!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="commission"
            label="Commission"
            rules={[{ required: false, message: "" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="join_date"
            label="Join Date"
            rules={[{ required: false, message: "" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Employee joining date (MM-DD-YYYY)"
            />
          </Form.Item>

          <Form.Item
            name="termination_date"
            label="Termination Date"
            rules={[{ required: false, message: "" }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "100%" }}
              placeholder="Employee termination date (MM-DD-YYYY)"
            />
          </Form.Item>
          <Form.Item
            name="overhead"
            label="Overhead Status"
            rules={[{ required: false, message: "" }]}
          >
            <Checkbox
              checked={isOverHead}
              onChange={(e) => setIsOverHead(e.target.checked)}
            >
              Overhead Employee
            </Checkbox>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-green-500"
              loading={result.isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default CreateEmployee;
